import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/logo-dark.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';

const CreatePassword = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();
    const { setAuth }: any = useAuth();
    const { token, identifier } = useParams();

    const userRef: any = useRef();
    const errorRef: any = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);

    const formSubmit = async (e: any) => {
        setErroMessage('')
        setSuccessMessage('')
        setLoading(true)
        e.preventDefault();
        if (password == passwordConfirmation) {
            let response: any = await api.setPassword(token, identifier, password).catch((e: any) => { }).catch((error: any) => { });
            setSuccessMessage("Successfully Set Password")
        } else {
            setErroMessage('Password Not Set')
        }
    }

    const sync = async () => {
        let response: any = await api.getUserDataFromToken(token, identifier).catch((e: any) => { }).catch((error: any) => { });
        console.log(response)
    }

    useEffect(() => {
        sync()
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [password])

    return (
        <form onSubmit={formSubmit} className='bg-light mx-auto my-4 card-body p-4 rounded' style={{ maxWidth: '430px', height: 'auto' }}>
            <div className="text-center mt-2">
                <h5 className="text-primary">Hi {username}!</h5>
                <p className="text-muted">Set Your Password.</p>
                {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
            </div>
            <div className='my-4 py-2 d-flex flex-column justify-content-start align-item-start'>
                <TextField required ref={userRef} variant="standard" value={password} onChange={(e) => { setPassword(e.target.value) }} name="password" className='w-100 my-2' type="password" label="Password" />
                <TextField required variant="standard" value={passwordConfirmation} onChange={(e) => { setPasswordConfirmation(e.target.value) }} name="passwordConfirmatiob" className='w-100 my-2' type="password" label="Password Confirmation" />
                <div className="w-100 d-flex justify-content-end"><Button disabled={load} type="submit" className="py-2 w-100" variant='contained'>
                    {!load ? "Create Password" : (
                        <CircularProgress size={23} />
                    )}
                </Button></div>
            </div>
        </form>
    );
}

export default CreatePassword;