import { atom } from 'recoil';
import API from '../services/api.services';

const api: API = new API();

export const NavExpandedState = atom({
    key: 'navsizestate',
    default: true
})
export const ActiveNavLink = atom({
    key: 'activenavlink',
    default: window.location.pathname,
})
export const logedin = atom({
    key: 'islogedin',
    default: false
})
export const openConfirmationMessage = atom({
    key: 'openconfirmationmessage',
    default: false,
})
export const confirmationTitle = atom({
    key: 'confirmationTitle',
    default: '',
})
export const confirmationMessage = atom({
    key: 'confirmationMessage',
    default: '',
})
export const confirmationCancel = atom({
    key: 'confirmationCancel',
    default: async () => {},
})
export const confirmationConfirm = atom({
    key: 'confirmationConfirm',
    default: async () => {},
})
export const modalTitleState = atom({
    key: 'modaltitle',
    default: "Title Prop",
})
export const modalChildrenState = atom({
    key: 'modalchildren',
    default: (<small>Children Prop</small>),
})
export const modalOpenedState = atom({
    key: 'modalopened',
    default: false,
})
export const thememode = atom({
    key: 'thememode',
    default: "dark", //api.getTheme(),
})
export const refreshProfile = atom({
    key: 'refreshProfile',
    default: false,
})
export const systemGlobalLoadingIndicator = atom({
    key: 'systemGlobalLoadingIndicator',
    default: false,
})
export const systemGlobalToastMessage = atom({
    key: 'systemGlobalToastMessage',
    default: {
        'error': false,
        'message': ''
    },
})
export const campaignJobSocket = atom({
    key: 'campaignJobSocket',
    default: {
        'state': "done",
        'progress': 100
    },
})
export const minimizedTemplateEditor = atom({
    key: 'minimizedTemplateEditor',
    default: true,
})
export const profile = atom({
    key: 'profileImage',
    default: sessionStorage.getItem("avatar"),
})