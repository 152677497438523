import Tooltip from "@mui/material/Tooltip";

const Summary = ({ toolTip = "", name = 'name', value = 'value', icon = null }: any) => {
    return (
        <div className="summary base-shadow p-4 flex-none d-flex m-2 justify-content-between align-items-start">
            <Tooltip title={toolTip}>
                <div className=" flex-none d-flex flex-column justify-content-between align-items-start">
                    <h5 className="m-0">{value}</h5>
                    <small className="fw-light">{name} ~ </small>
                </div>
            </Tooltip>
            <div className="icon h-100 d-flex justify-content-center align-items-center">
                {icon}
            </div>
        </div>
    );
}

export default Summary;