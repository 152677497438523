import Appbar from "./utilities/appbar";
import Footer from "./utilities/footer";
import SideNav from "./utilities/sidenav";
import { Routes, Route, useNavigate } from 'react-router-dom';
import CustomCard from "./components/card";
import Profile from "./pages/profile";
import Navigation, { UnauthNavigation } from "./services/navigation.links";
import ModelsContext from "./components/models";
import { useEffect, useState } from "react";
import Redirect from "./pages/redirect";
import { useRecoilState } from "recoil";
import { ActiveNavLink, minimizedTemplateEditor } from "./context/atoms";
import Settings from "./pages/settings/settings";
import UserRegistration from "./pages/settings/users/register";

const Layout = () => {

    const navigate = useNavigate();
    const navigations: any = Navigation();
    const unauthNavigations: any = UnauthNavigation();
    const [navigation, setNav] = useState([])
    const [activeNavLink, setActiveNavLink] = useRecoilState(ActiveNavLink);
    const [isMinimized, setMinimized] = useRecoilState<any>(minimizedTemplateEditor);

    useEffect(() => {
        let _nav: any = []
        navigations.forEach((nav: any) => {
            if (nav.parent) {
                _nav.push(...nav.children)
            } else {
                _nav.push(nav)
            }
        })
        if (navigation.length == 0)
            setNav(_nav)
    })

    useEffect(() => {
        let currntRoute: any = window.location.pathname;
        unauthNavigations?.forEach((nav: any, index: number) => {
            if (currntRoute?.includes(nav?.link)) {
                setActiveNavLink("/");
                navigate("/")
            }
        })
    }, [])


    return (
        <ModelsContext>
            <div className="main w-100 text-light h-100 d-flex">
                {
                    isMinimized ? (<SideNav />) : null
                }

                <div className="main-space w-100 d-flex flex-column overflow-hidden">
                    {
                        isMinimized ? (<Appbar />) : null
                    }

                    <div className="main-work-space">

                        <CustomCard>

                            <Routes>
                                {
                                    navigation.map((nav: any, index) => {
                                        let Component: any = nav.component;
                                        return (<Route path={nav.link} key={nav.link} element={<Component />} />)
                                    })
                                }
                                <Route path="/verify/:token" element={<Redirect />} />
                                <Route path="/settings" element={<Settings />} />
                                <Route path="/settings/user" element={<Settings />} />
                                <Route path="/settings/preference" element={<Settings />} />
                                <Route path="/settings/user/registration" element={<UserRegistration />} />
                                <Route path="/settings/user/users" element={<Settings />} />
                                <Route path="/settings/user/invites" element={<Settings />} />
                            </Routes>

                        </CustomCard>

                    </div>

                    <Footer />

                </div>

            </div>
        </ModelsContext>
    );
}

export default Layout;