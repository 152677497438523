import { useEffect, useState } from "react";
import CardAppBar from "../../../components/cardAppbar";
import { CutomAutoComplete, CustomTextField, IconTextField } from "../../../components/textfield";
import { BsEnvelopeFill, BsFillTelephoneFill } from "react-icons/bs";
import Countries from "../../../services/countries";
import { RiSaveLine } from "react-icons/ri";
import LoaderIconButton from "../../../components/loaderIconButton";
import useApiServices from "../../../hooks/useAxios";
import { useNavigate } from "react-router-dom";

const UserRegistration = () => {

    const navigate = useNavigate();
    const COUNTRIES: Countries = new Countries();
    const api: any = useApiServices();

    const [isSaving, setIsSeving] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<any>("");
    const [lastName, setLastName] = useState<any>("");
    const [username, setUsername] = useState<any>("");
    const [emailAddress, setEmailAddress] = useState<any>("");
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const [country, setCountry] = useState<any>(null);
    const [countries, setCountries] = useState<any[]>([]);
    const [message, setMessage] = useState<any>("");

    const registerUser: any = async (event: any) => {
        event.preventDefault();
        setMessage("")
        setIsSeving(true)
        if (
            firstName == "" || firstName == undefined || firstName == null ||
            lastName == "" || lastName == undefined || lastName == null ||
            username == "" || username == undefined || username == null ||
            emailAddress == "" || emailAddress == undefined || emailAddress == null ||
            phoneNumber == "" || phoneNumber == undefined || phoneNumber == null ||
            country?.value == "" || country?.value == undefined || country?.value == null
        ) {
            setMessage("Please fill all fields")
            setIsSeving(false);
            return
        }

        const formData: FormData = new FormData();
        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
        formData.append('username', username)
        formData.append('email', emailAddress)
        formData.append('phone_number', phoneNumber)
        formData.append('country', country?.value)
        let response: any = await api.registerUser(formData).catch((error: any) => { });
        if(response?.success){
            navigate("/settings/user")
        }
        console.log(response)
        setIsSeving(false)
    }

    useEffect(() => {

        let _countries: any[] = []
        COUNTRIES.countries.forEach((country: any, index: any) => {
            _countries.push({
                label: `${country?.name} (${country?.code})`,
                value: country?.name
            })
        })
        setCountries(_countries)

        return () => {

        }
    }, [])

    return (
        <div className="custom-card m-3 d-flex flex-column">
            <div className="d-flex flex-column w-100 justify-content-between align-items-start">
                <CardAppBar
                    title="User Registration"
                    subTitle="System user registration"
                    canNavigateBack={true}
                    backLink="/settings/user"
                    isProcessing={false}
                    loadingProgressText={message}
                >
                    <LoaderIconButton toolTip="Register User" onClick={registerUser} icon={<RiSaveLine />} isLoading={isSaving} />
                </CardAppBar>
                <div className="d-flex align-items-center py-2 w-100">
                    <form onSubmit={registerUser} className="w-100 d-flex flex-row">
                        <div className="w-50 d-flex flex-column mx-4">
                            <CustomTextField disabled={isSaving} onChange={(e: any) => setFirstName(e.target.value)} label={"First Name"} errorField={firstName} />
                            <CustomTextField disabled={isSaving} onChange={(e: any) => setLastName(e.target.value)} label={"Last Name"} errorField={lastName} />
                            <CustomTextField disabled={isSaving} onChange={(e: any) => setUsername(e.target.value)} label={"Username"} errorField={username} />
                            <IconTextField icon={<BsFillTelephoneFill />} disabled={isSaving} errorField={phoneNumber} onChange={(e: any) => { setPhoneNumber(e.target.value); }} label="Phone Number" type="text" />
                            <IconTextField icon={<BsEnvelopeFill />} disabled={isSaving} errorField={emailAddress} onChange={(e: any) => { setEmailAddress(e.target.value); }} label="Email Address" type="email" />
                            <CutomAutoComplete disabled={isSaving} onChange={(e: any, value: any) => setCountry(value)} label={"Country"} errorField={country} list={countries} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserRegistration;