import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/logo.png';
import BackgroundImage1 from '../assets/shape1_1.png';
import BackgroundImage2 from '../assets/shape2_1.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';

const Recover = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();
    const { setAuth }: any = useAuth();
    const { token, email }: any = useParams();

    const userRef: any = useRef();
    const errorRef: any = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);
    const [isVerifyingPasswordToken, setVerifyingPasswordToken] = useState(true);
    const [isTokenValid, setTokenValid] = useState(false);

    const formSubmit = async (e: any) => {
        setErroMessage('')
        setSuccessMessage('')
        setLoading(true)
        e.preventDefault();
        if (password == passwordConfirmation) {
            let response: any = await api.setPassword(token, email, password).catch((e: any) => { }).catch((error: any) => { });
            setSuccessMessage("Successfully Set Password")
        } else {
            setErroMessage('Password Not Set')
        }
    }

    const sync = async () => {
        const formData: FormData = new FormData();
        formData.append('token', token);
        formData.append('email', email);
        let response: any = await api.validatePasswordResetToken(formData).catch((e: any) => { }).catch((error: any) => { });
        setTokenValid(response?.success)
        if (response?.success) {
            setSuccessMessage(response?.message)
        } else {
            setErroMessage(response?.message)
        }
        setVerifyingPasswordToken(false)
    }

    useEffect(() => {
        sync()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [password])

    return (
        <form onSubmit={formSubmit} className={`${api.getTheme() == "light" ? "" : ""} my-4 w-100 card-body p-4 rounded`} style={{ height: 'auto', backgroundColor: api.getTheme() == "light" ? "" : "var(--backgroundDimmerColor)" }}>
            {!isVerifyingPasswordToken ? isTokenValid == true ? (
                <>
                    <div className="text-center mt-2">
                        <h5 className="text-primary">Hi {username}!</h5>
                        <p className="text-muted">Set Your Password.</p>
                        {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                        {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
                    </div>
                    <div className='my-0 py-2 d-flex flex-column justify-content-start align-item-start'>
                        <TextField required ref={userRef} variant="standard" value={password} onChange={(e) => { setPassword(e.target.value) }} name="password" className='w-100 my-2' type="password" label="Password" />
                        <TextField required variant="standard" value={passwordConfirmation} onChange={(e) => { setPasswordConfirmation(e.target.value) }} name="passwordConfirmatiob" className='w-100 my-2' type="password" label="Password Confirmation" />
                        <div className="w-100 my-2 d-flex justify-content-end"><Button disabled={load} type="submit" className="py-2 w-100" variant='contained'>
                            {!load ? "Create Password" : (
                                <CircularProgress size={23} />
                            )}
                        </Button></div>
                    </div>
                </>
            ) : (
                <Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>
            ) : (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <CircularProgress thickness={1} size={52} />
                    <p className='p-2 m-0'><b>Validating</b></p>
                    <small><i>Please wait....</i></small>
                </div>
            )}
        </form>
        );
}

export default Recover;