import Button from '@mui/material/Button';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import './components.css';

const Collapsable = (props: any) => {
    const { children, title, defaultOpen=false } = props;
    const [isOpen, setOpen] = useState(defaultOpen);

    let toggle = (event:any) => {
        setOpen(!isOpen)
    }

    return (
        <div className='w-100' style={{borderBottom: '1px solid var(--backgroundDimmerColor)'}}>
            <Button onClick={toggle} className='text-capitalize mx-0 my-2 d-flex justify-content-between text-dark w-100'>
                <div className='mx-2 fs-12' style={{color: 'var(--textColor)', fontSize: '9pt'}}>{title}</div>
                {(isOpen) ? (<IoIosArrowUp style={{color: 'var(--textColor)'}}/>) : (<IoIosArrowDown style={{color: 'var(--textColor)'}}/>)}
            </Button>
            {
                (isOpen) ? (
                    <div className="text-dark">
                        {children}
                    </div>
                ) : null
            }
        </div>
    );
}

export default Collapsable;