import API from '../services/api.services';
import useAuth from './useAuth';

const apiServices: API = new API()

const useRefreshToken = () => {

    const { setAuth }: any = useAuth()

    const refresh = async () => {
        const response: any = await apiServices.getRefreshToken().catch((e: any)=>{})
        setAuth((prev: any) => {
            return {
                ...prev,
                access: prev.access,
            }
        })
        if(response?.access)
        return response?.access;
        else
        return 'invalid';
    }
    return refresh;
}

export default useRefreshToken;