import './components.css';

const CustomCard = (props:any) => {
    const { children, className } = props;
    return ( 
        <div className={className}>
            {children}
        </div>
    );
}
 
export default CustomCard;