import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import { ExcelData } from "./interfaces";

export default class API {
    debug = false;
    dateOptions: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    host = this.debug ? "http://127.0.0.1:8000" : "https://api.fugas.co.tz";
    socketHost:any = this.debug ? "ws://127.0.0.1:8000" : "ws://api.fugas.co.tz";
    apiVersion: string = "v1"; // should not end with foreward slash
    url: string = `${this.host}/${this.apiVersion}`; // should not end with foreward slash
    storeName: any; // sessionStorage.getItem('store_name') || ""
    storeId: any; //sessionStorage.getItem('store_id') || ""
    salesRep: any = 3; //sessionStorage.getItem('sales_rep') || ""
    controller: AbortController;
    avatar: any;
    firstName: any;
    lastName: any;
    username: any;
    phoneNumber: any;
    email: any;
    country: any;
    axiosInstance = axios.create({
        baseURL: this.host,
        withCredentials: true,
    })
    cancelToken: any;

    headers: any = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    constructor(controller?: AbortController | null | undefined) {
        this.controller = controller ?? new AbortController();
        // this.headers['signal'] = this.controller.signal;
        // /////////////////////////////////////////////////// //
        this.avatar = sessionStorage.getItem('avatar');
        this.firstName = sessionStorage.getItem('first_name');
        this.lastName = sessionStorage.getItem('last_name');
        this.username = sessionStorage.getItem('username');
        this.country = sessionStorage.getItem('country');
        this.phoneNumber = sessionStorage.getItem('phone_number');
        this.email = sessionStorage.getItem('email');
        this.storeId = sessionStorage.getItem('store');
        this.storeName = sessionStorage.getItem('store_name');
        // /////////////////////////////////////////////////// //
        axios.defaults.withCredentials = true;
        this.axiosInstance.defaults.withCredentials = true;
        // this.axiosInstance.defaults.signal = this.controller.signal;
        // this.cancelToken = axios.CancelToken.source();
        // this.axiosInstance.defaults.cancelToken = this.cancelToken;
    }

    async login(formData: FormData) {
        let response: any = await this.axiosInstance.post(`/auth/login/`,
            formData,
            {
                headers: {
                    ...this.headers,
                }
            }).catch((error: any) => {
                return {
                    'success': false,
                    'message': error?.response?.data?.detail
                };
            })

        if (response?.data?.success) {
            this.setTheme("light");
            return response.data;
        }
        return {
            'success': false,
            'message': response.message
        };
    }

    async getRefreshToken() {
        let response: any = await this.axiosInstance.post(`/auth/refresh/`, {},
            {
                headers: this.headers,
            })

        return response.data;
    }

    async logout() {
        let response: any = await this.axiosInstance.post(`/auth/logout/`, {},
            {
                headers: this.headers,
            }).then((value: any)=>{
                sessionStorage.clear();
                localStorage.clear();
                return value;
            })

        return response.data;
    }

    async resetPassword(data: FormData) {
        let response = await axios.post(`${this.host}/auth/reset-password-email/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async validatePasswordResetToken(data: FormData) {
        let response = await axios.post(`${this.host}/auth/validate-password-reset-token/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async abortRequest() {
        // console.log("Canceled")
        // this.cancelToken.cancel();
        // this.controller.abort();
    }

    async registerOwner(formData: FormData) {
        let response = await this.axiosInstance.post(`${this.host}/auth/register-owner/`,
            formData,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async registerBusiness(formData: FormData, uid: any) {
        let response = await this.axiosInstance.post(`${this.host}/auth/register-business/${uid}/`,
            formData,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getShops() {
        let response = await this.axiosInstance.get(`${this.url}/business/shops/1/`,
            { headers: this.headers },
        )

        return response.data;
    }

    async getStudents() {
        let response = await this.axiosInstance.get(`${this.url}/school/students/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getProducts(store: any = null) {
        let response = await this.axiosInstance.get(`${this.url}/store/get-products/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getStock() {
        let response = await this.axiosInstance.get(`${this.url}/store/get-stock/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getStores() {
        let response = await this.axiosInstance.get(`${this.url}/store/get-all-stores/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getInventory() {
        let response = await this.axiosInstance.get(`${this.url}/store/get-stock/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getTemplates() {
        let response = await this.axiosInstance.get(`${this.url}/campaign/get-email-templates/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async deleteTemplate(template_id:any) {
        let response = await this.axiosInstance.delete(`${this.url}/campaign/delete-email-template/${template_id}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async saveTemplate(data:FormData) {
        let response = await this.axiosInstance.post(`${this.url}/campaign/create-email-template/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    
    async getCampaigns() {
        let response = await this.axiosInstance.get(`${this.url}/campaign/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getPatient(pid: any) {
        let response = await this.axiosInstance.get(`${this.url}/clinic/get-patient/${pid}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getCategories() {
        let response = await this.axiosInstance.get(`${this.url}/clinic/get-categories/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getStates() {
        let response = await this.axiosInstance.get(`${this.url}/clinic/get-states/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getState(state_id:any) {
        let response = await this.axiosInstance.get(`${this.url}/clinic/get-state/${state_id}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async createState(data:FormData) {
        let response = await this.axiosInstance.post(`${this.url}/clinic/create-state/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async deleteState(state_id:any) {
        let response = await this.axiosInstance.delete(`${this.url}/clinic/delete-state/${state_id}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async updateState(state_id:any, data:FormData) {
        let response = await this.axiosInstance.put(`${this.url}/clinic/update-state/${state_id}/`, data ,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getSales() {
        let response = await this.axiosInstance.get(`${this.url}/sale/get-all-sales/`,
            {
                headers: this.headers,
            })
        return response.data;
    }

    async getSale(sale_id: any) {
        let response = await this.axiosInstance.get(`${this.url}/sale/get-sale/${sale_id}/`,
            {
                headers: this.headers,
            })
        return response.data;
    }

    async getUsers() {
        let response = await this.axiosInstance.get(`${this.url}/user/`,
            {
                headers: this.headers,
            })
        return response.data;
    }

    async getUser(userId: number) {
        let response = await this.axiosInstance.get(`${this.url}/user/${userId}/`,
            {
                headers: this.headers,
            })
        return response.data;
    }

    async getUsersInvites() {
        let response = await this.axiosInstance.get(`${this.url}/user/invites/`,
            {
                headers: this.headers,
            })
        return response.data;
    }
    
    async cancelUserInvite(inviteId: number) {
        let response = await this.axiosInstance.post(`${this.url}/user/delete-invite/${inviteId}/`, {},
            {
                headers: this.headers,
            })

        return response.data;
    }
    
    async resendUserInvite(inviteId: number) {
        let response = await this.axiosInstance.post(`${this.url}/user/resend-invite/${inviteId}/`, {},
            {
                headers: this.headers,
            })

        return response.data;
    }
    
    async registerUser(data:FormData) {
        let response = await this.axiosInstance.post(`${this.url}/user/register/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }
    
    async verifyAccount(token:string) {
        let response = await axios.put(`${this.host}/auth/verify/?token=${token}`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getMailingList() {
        let response = await this.axiosInstance.get(`${this.url}/clinic/mailing-list/`,
            {
                headers: this.headers,
            })
        return response.data;
    }

    async getMailingListPatients(mailing_list_id: any) {
        let response = await this.axiosInstance.get(`${this.url}/clinic/mailing-list/${mailing_list_id}/`,
            {
                headers: this.headers,
            })
        return response.data;
    }
        
    async getCampaignReceipients(campaign_id:any) {
        let response = await this.axiosInstance.get(`${this.url}/campaign/receipients/${campaign_id}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async freeSend(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/campaign/free-send/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async sendCampaign(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/campaign/send-campaign/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async rebuildMailingList() {
        let response = await this.axiosInstance.post(`${this.url}/campaign/rebuild-mailing-list/`, {},
            { headers: this.headers },
        )

        return response.data;
    }

    async registerPatient(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/clinic/register-patient/`, data,
            { headers: this.headers },
        )
        return response.data;
    }

    async createSale(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/sale/create-sales/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async createProduct(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/store/create-product/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async insertStockWithoutSerial(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/store/new-stock/?itter=qty`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async insertStockWithSerial(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/store/new-stock/?itter=serial`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async createStore(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/store/create-store/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async uploadAvatar(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/user/update-avatar/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async saveImage(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/storage/save-image/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async getImages() {
        let response = await this.axiosInstance.get(`${this.url}/storage/images/`,
            { headers: this.headers },
        )

        return response.data;
    }

    async deleteStore(storeId: any | null) {
        let response = await this.axiosInstance.delete(`${this.url}/store/delete-store/${storeId}/`,
            { headers: this.headers },
        )

        return response.data;
    }

    async deletePatient(pid: any | null) {
        let response = await this.axiosInstance.delete(`${this.url}/clinic/delete-patient/${pid}/`,
            { headers: this.headers },
        )

        return response.data;
    }
    
    async updatePatient(patient_id: any, data: any) {
        let response = await this.axiosInstance.put(`${this.url}/clinic/update-patient/${patient_id}/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    hexToRgb(hex: any) {
        var c:any;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
        }
        throw new Error('Bad Hex');
    }

    numberWithCommas(input: number) {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    parseDate(date_input: any) {
        let parsedDate: Date = new Date(date_input)
        let date = parsedDate.toLocaleDateString("en-US", this.dateOptions)
        return date;
    }

    capitalizeString(string: string) {
        return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    };
    
    exportExcel(data:ExcelData) {
        let dateTime: Date = new Date();
        let fileName = `${dateTime.toLocaleDateString().replaceAll("_", "")}${dateTime.getTime().toString().replaceAll("_", "")}`
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: fileName,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: data.headers // <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        return csvExporter.generateCsv(data.rows);
    }

    setTheme(theme: string) {
        localStorage.setItem("themeMode", theme)
        return theme;
    };

    getTheme() {
        let theme = localStorage.getItem("themeMode")
        return theme || "light";
    };

    getUserType() {
        let userType = sessionStorage.getItem("user_type")
        return userType;
    };

    generateRandomNumber(maintainList:Array<number>, from: number = 0, to: number = 10) {
        let randomNumber: number = Math.floor(Math.random() * to)
        if(randomNumber < from){
            this.generateRandomNumber(maintainList, from, to)
            return
        }
        if(maintainList.includes(randomNumber)){
            this.generateRandomNumber(maintainList, from, to)
            return
        }
        return randomNumber;
    };

}
