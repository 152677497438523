import { useState } from "react";
import { MdOutlineUpdate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CardAppBar from "../../components/cardAppbar";
import LoaderIconButton from "../../components/loaderIconButton";
import { CustomTextField } from "../../components/textfield";
import useApiServices from "../../hooks/useAxios";
import API from "../../services/api.services";



const CreateStore = () => {

    const api: any = useApiServices();
    const navigate = useNavigate();

    const [progressMessage, setProgressMessage]: any = useState(null);
    const [isSubmittingSale, setIsSubmittingSale]: any = useState(null);
    const [storeName, setStoreName] = useState("");

    const createSale = async (event: any) => {
        setIsSubmittingSale(true);
        if(storeName == "" || storeName == null || storeName == undefined){
            setProgressMessage("Please Fill The Store's Name Field");
            setIsSubmittingSale(false);
            return
        }
        try{
            let formData: FormData = new FormData();
            formData.append("name", storeName);
            let response = await api.createStore(formData)
            if (response.success) {
                navigate("/stores")
            }
        }catch(e){}
        setProgressMessage(null);
        setIsSubmittingSale(false);

    }

    return (
        <div className="p-3">
            <div className="custom-card d-flex flex-column align-items-start">
                <div className="d-flex flex-column w-100 justify-content-between align-items-start">
                    <CardAppBar
                        title={"Register Shop"}
                        subTitle="Enter shop's name to register"
                        backLink="/shops"
                        loadingProgressText={progressMessage}
                        isProcessing={isSubmittingSale}
                    >
                        <LoaderIconButton icon={<MdOutlineUpdate />} toolTip={"Reset"} />
                        <LoaderIconButton toolTip={"Create Sale"} onClick={createSale} isLoading={isSubmittingSale} />
                    </CardAppBar>
                </div>
            <form onSubmit={(e) => { createSale(e) }} className="d-flex flex-column my-3 mx-2 w-50" action="">
                <CustomTextField disabled={isSubmittingSale} errorField={storeName} onChange={(e: any) => { setStoreName(e.target.value); }} label="Shop Name" type="text" />
                <CustomTextField disabled={isSubmittingSale} errorField={storeName} onChange={(e: any) => { setStoreName(e.target.value); }} label="Shop Location" type="text" />
                <CustomTextField disabled={isSubmittingSale} errorField={storeName} onChange={(e: any) => { setStoreName(e.target.value); }} label="Coordinates" type="text" />
            </form>
            </div>
        </div>
    );
}

export default CreateStore;