import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useApiServices from '../hooks/useAxios';
import { useRecoilState } from 'recoil';
import { logedin } from '../context/atoms';

const Redirect = () => {

    const navigate = useNavigate();
    const api: any = useApiServices();
    const [isLogedin, setIsLogedIn] = useRecoilState(logedin);

    const sync = async () => {
        let response: any = await api.logout().catch((error: any)=>{});

        if(response?.success){
            setIsLogedIn(false);
        }

    }

    useEffect(() => {
        sync();
        return () => {

        }
    }, [])

    return (
        <div className="custom-card m-3 d-flex flex-column">
            <div style={{ height: '500px' }} className="d-flex flex-column justify-content-center align-items-center">
                <p className="p-0 m-0"><b>Redirecting</b></p>
                <small><i>Please wait....</i></small>
            </div>
        </div>
    );
}

export default Redirect;