import { BsCalendar3, BsCalendar4, BsFileCode } from 'react-icons/bs';
import { RiChatFollowUpLine, RiMailSettingsFill, RiProductHuntFill } from 'react-icons/ri';
import { FiBox, FiMapPin, FiPackage, FiSend, FiUsers } from 'react-icons/fi';
import { MdDeliveryDining, MdInsights,MdInventory,MdLeaderboard,MdOutlineBorderColor, MdOutlineInventory, MdOutlineInventory2, MdOutlineMailOutline, MdOutlineSpaceDashboard, MdOutlineStore, MdProductionQuantityLimits } from 'react-icons/md';
import { AiOutlineCalendar, AiOutlineShop, AiOutlineShopping } from 'react-icons/ai';
import { HiOutlineStatusOffline, HiOutlineUserCircle, HiUsers } from 'react-icons/hi';
import { GrContactInfo} from 'react-icons/gr';
import { BsTextIndentLeft } from 'react-icons/bs';
import { IoMdGlobe } from 'react-icons/io';
import Dashboard from '../pages/dashboard';
import { FaConciergeBell, FaHospitalUser, FaMapMarkedAlt, FaSalesforce, FaUserAlt, FaUserCircle } from 'react-icons/fa';
import { BiMailSend, BiMessageSquareError } from 'react-icons/bi';
// import Patients from '../pages/patient/patients';
import Orders from '../pages/orders/orders';
import Shops from '../pages/shop/shop';
import Profile from '../pages/profile';
import CreateStore from '../pages/shop/create';
import Users from '../pages/settings/users';
import Suppliers from '../pages/suppliers/suppliers';
import AdminDashboard from '../admin-pages/dashboard';
import Busunesses from '../admin-pages/business/businesses';
import AdminUsers from '../admin-pages/users/users';

const Navigation = () => {

    const iconSize: number = 16;

    return [
        {
            parent: false,
            label: false,
            name: 'Dashboard',
            link: '/',
            icon: <MdOutlineSpaceDashboard size={iconSize} />,
            clickable: true,
            component: Dashboard,
            permissions: ["owner", "manager"]
        },
        {
            parent: false,
            label: false,
            name: 'Orders',
            link: '/orders',
            icon: <FaConciergeBell size={iconSize} />,
            clickable: true,
            component: Orders,
            permissions: ["owner", "manager"],
        },
        {
            parent: false,
            label: false,
            name: 'Suppliers',
            link: '/suppliers',
            icon: <MdDeliveryDining size={iconSize} />,
            clickable: true,
            component: Suppliers,
            permissions: ["owner", "manager"]
        },
        {
            parent: false,
            label: false,
            name: 'Shops',
            link: '/shops',
            icon: <AiOutlineShop size={iconSize} />,
            clickable: true,
            component: Shops,
            permissions: ["owner"]

        },
        {
            parent: false,
            label: false,
            name: 'Shops',
            link: '/shops/create',
            icon: <AiOutlineShop size={iconSize} />,
            clickable: false,
            component: CreateStore,
            permissions: ["owner"]

        },
        {
            parent: false,
            label: false,
            name: 'Products',
            link: '/products',
            icon: <RiProductHuntFill size={iconSize} />,
            clickable: true,
            component: CreateStore,
            permissions: ["owner", "manager"]

        },
        {
            parent: false,
            label: false,
            name: 'Stock',
            link: '/stock',
            icon: <MdInventory size={iconSize} />,
            clickable: true,
            component: CreateStore,
            permissions: ["owner", "manager"]

        },
        {
            parent: false,
            label: false,
            name: 'My Profile',
            link: '/profile',
            icon: <FaUserCircle size={iconSize} />,
            clickable: true,
            component: Profile,
            permissions: ["owner", "manager", "admin"]

        },
    ];

}

export const UnauthNavigation = () => {
    
    const unauthRoutes: Array<Object> = [
        {
            name: 'Login',
            link: '/login',
        },
        {
            name: 'Verify',
            link: '/verify',
        },
        {
            name: 'Create Password',
            link: '/create-password',
        },
        {
            name: 'Reset Password',
            link: '/reset-password',
        },
        {
            name: 'Set Password',
            link: '/set-password',
        },
        {
            name: 'Reset Password',
            link: '/reset-password',
        },
    ]

    return unauthRoutes;
}

export const AdminNavigation = () => {
   
    const iconSize: number = 16;

    const adminNav: Array<Object> = [
        {
            parent: false,
            label: false,
            name: 'Dashboard',
            link: '/',
            icon: <MdOutlineSpaceDashboard size={iconSize} />,
            clickable: true,
            component: AdminDashboard
        },
        {
            parent: false,
            label: false,
            name: 'Map',
            link: '/map',
            icon: <FaMapMarkedAlt size={iconSize} />,
            clickable: true,
            component: AdminDashboard
        },
        {
            parent: true,
            label: true,
            name: 'Businesses',
            defaultOpen: false,
            children: [
                {
                    parent: false,
                    label: false,
                    name: 'Businesses',
                    link: '/business',
                    icon: <BsCalendar3 size={iconSize} />,
                    clickable: true,
                    component: Busunesses
                },
                {
                    parent: false,
                    label: false,
                    name: 'Ranking',
                    link: '/ranking',
                    icon: <MdLeaderboard size={iconSize} />,
                    clickable: true,
                    component: Busunesses
                },
            ],
        },
        {
            parent: true,
            label: true,
            name: 'Platform',
            defaultOpen: false,
            children: [
                {
                    parent: false,
                    label: false,
                    name: 'Packages',
                    link: '/package',
                    icon: <BsCalendar3 size={iconSize} />,
                    clickable: true,
                    component: Busunesses
                },
            ],
        },
        {
            parent: true,
            label: true,
            name: 'Reports',
            defaultOpen: false,
            children: [
                {
                    parent: false,
                    label: false,
                    name: 'UserEngagement',
                    link: '/user-engagement',
                    icon: <BsCalendar3 size={iconSize} />,
                    clickable: true,
                    component: Busunesses
                },
                {
                    parent: false,
                    label: false,
                    name: 'Crush Reports',
                    link: '/crash-reports',
                    icon: <BsCalendar3 size={iconSize} />,
                    clickable: true,
                    component: Busunesses
                },
            ],
        },
        {
            parent: false,
            label: false,
            name: 'Users',
            link: '/users',
            icon: <HiUsers size={iconSize} />,
            clickable: true,
            component: AdminUsers,

        },
        {
            parent: false,
            label: false,
            name: 'My Profile',
            link: '/profile',
            icon: <FaUserCircle size={iconSize} />,
            clickable: true,
            component: Profile,

        },
    ];

    return adminNav
}

export default Navigation;