import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { modalOpenedState, modalChildrenState, modalTitleState } from "../context/atoms";
import { PopUpModalParameters } from "../services/interfaces";

const useWidgetModal = () => {
    // ///////////////////////////////////////////////////////////// //
    const [openModal, setOpenModal] = useRecoilState(modalOpenedState);
    const [modalChildren, setModalChildren] = useRecoilState(modalChildrenState);
    const [modalTitle, setModalTitle] = useRecoilState(modalTitleState);

    // ///////////////////////////////////////////////////////////// //
    const modal = async ({ title = "No Title", children }: PopUpModalParameters) => {
        setModalTitle(title);
        setModalChildren(children);
        setOpenModal(true);
    }
    // ///////////////////////////////////////////////////////////// //
    useEffect(() => {
        // setOpenConfirmation(true)
    }, [])

    return modal;
}

export default useWidgetModal;