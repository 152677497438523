import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import { openConfirmationMessage, confirmationCancel, confirmationConfirm, modalTitleState, modalChildrenState, modalOpenedState } from "../context/atoms";

const ConfirmationModelView = ({ open=false, title = "", message = "", onCancel = async () => { }, onConfirm = async () => { } }: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useRecoilState(openConfirmationMessage);
    const [getConfirmationCancel, setConfirmationCancel] = useRecoilState(confirmationCancel);
    const [getConfirmationConfirm, setConfirmationConfirm]: any = useRecoilState(confirmationConfirm);

    const handleConfirm = async (event:any) => {
        setIsLoading(true)
        await getConfirmationConfirm[0]().catch((error:any)=>{
            setIsLoading(false)
            setOpenConfirmation(false)
        });
        setOpenConfirmation(false)
        setIsLoading(false)
    }

    const handleCancel = async (event:any) => {
        await getConfirmationConfirm[1]().catch((error:any)=>{
            setIsLoading(false)
            setOpenConfirmation(false)
        });
        setIsLoading(false)
        setOpenConfirmation(false)
    }

    return (
        <div>
            <Dialog fullWidth={true} open={open} onClose={async (e) => { await onCancel() }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <DialogActions>
                    {
                        isLoading ? (
                            <div className="px-4">
                                <CircularProgress style={{ color: 'black' }} size={22} />
                            </div>
                        ) : (
                            <Button onClick={handleConfirm}>Yes</Button>
                        )
                    }
                    <Button onClick={handleCancel}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const ViewUserModel = ({ open=false, title = "", message = "", onCancel = async () => { }, onConfirm = async () => { } }: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useRecoilState(openConfirmationMessage);
    const [getConfirmationCancel, setConfirmationCancel] = useRecoilState(confirmationCancel);
    const [getConfirmationConfirm, setConfirmationConfirm]: any = useRecoilState(confirmationConfirm);

    const handleConfirm = async (event:any) => {
        setIsLoading(true)
        await getConfirmationConfirm[0]();
        setOpenConfirmation(false)
        setIsLoading(false)
    }

    const handleCancel = async (event:any) => {
        await getConfirmationConfirm[1]();
        setOpenConfirmation(false)
    }

    return (
        <div>
            <Dialog fullWidth={true} open={open} onClose={async (e) => { await onCancel() }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <DialogActions>
                    {
                        isLoading ? (
                            <div className="px-4">
                                <CircularProgress style={{ color: 'black' }} size={22} />
                            </div>
                        ) : (
                            <Button onClick={handleConfirm}>Yes</Button>
                        )
                    }
                    <Button onClick={handleCancel}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const PopUpModelView = () => {

    const [openModal, setOpenModal] = useRecoilState(modalOpenedState);
    const [modalChildren, setModalChildren] = useRecoilState(modalChildrenState);
    const [modalTitle, setModalTitle] = useRecoilState(modalTitleState);

    return (
        <div>
            <Dialog fullWidth={true} open={openModal} onClose={(e)=>{setOpenModal(false)}}>
                <DialogTitle>{modalTitle}</DialogTitle>
                <DialogContent>
                    {modalChildren}
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default ConfirmationModelView;