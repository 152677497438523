import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import BackgroundImage1 from '../assets/shape1_1.png';
import BackgroundImage2 from '../assets/shape2_1.png';
import LogoLight from '../assets/hd-logo-light.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
const Login = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();
    const { setAuth }: any = useAuth();

    const userRef: any = useRef();
    const errorRef: any = useRef();

    const [isLogedin, setIsLogedin] = useRecoilState(logedin)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);

    const formSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        setErroMessage('')
        setSuccessMessage('')

        let formData:FormData = new FormData();
        
        formData.append("username", email);
        formData.append("password", password);

        let response: any = await api.login(formData).catch((e: any) => { }).catch((error: any) => { });
        const accessToken: string = response?.access_token
        console.log(response)
        setAuth({ ...response })
        
        setLoading(false)
        if (response?.success) {
            setSuccessMessage("Login successfully")
            delete response['access']
            delete response['active']
            delete response['archive']
            delete response['is_active']
            delete response['success']
            response['avatar'] = `${api.host}${response['avatar']}`
            Object.keys(response).forEach((key: any) => {
                sessionStorage.setItem(key, response[key]);
            })
            navigate(0);
        }
        else {
            setErroMessage(response?.message ?? "Server error, Please try again later")
        }
    }

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [email, password])

    return (
        <form onSubmit={formSubmit} className={`${api.getTheme() == "light" ? "" : ""} my-4 w-100 card-body p-4 rounded`} style={{ height: 'auto' }}>
            <div className="text-center mt-2">
                <h5 className="" style={{color: 'var(--textColor)'}}>Welcome!</h5>
                <p style={{ color: "var(--textLightColor)" }} className="">Sign in to Fugas.</p>
                {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
            </div>
            <div className='my-4 py-2 d-flex flex-column justify-content-start align-item-start'>
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={userRef}
                    variant="standard"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    name="email"
                    className='w-100 my-2'
                    type="text"
                    label="Username"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    variant="standard"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    name="password"
                    className='w-100 my-2'
                    type="password"
                    label="Password"
                    FormHelperTextProps={{style: {color: 'var(--textColor)'}}}
                />
                <div className='mx-0 d-flex align-items-center justify-content-end'><Link style={{ color: 'var(--primaryColor)' }} className='text-decoration-none' to="/reset-password">Forgot password?</Link></div>
                <div className='mx-0 d-flex align-items-center' style={{ color: "var(--textLightColor)" }}><Checkbox className='m-0' name='remember-me' /> Remember Me</div>
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex justify-content-end w-100"><Button style={{ backgroundColor: 'var(--primaryColor)' }} disabled={load} disableElevation type="submit" className="py-2 w-100 my-2 mx-1" variant='contained'>
                        {!load ? "Login" : (
                            <CircularProgress size={23} />
                        )}
                    </Button></div>
                    <div className="d-flex justify-content-end w-100 text-decoration-none">
                        <Link className='w-100' to="/register">
                            <Button style={{ backgroundColor: 'var(--primaryColor)' }} disabled={load} disableElevation type="submit" className="py-2 w-100 my-2 mx-1" variant='contained'>
                                SignUp
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Login;