import { GoogleMap } from "@react-google-maps/api";
import { useMemo } from 'react';
import './style.css';
import { thememode } from "../context/atoms";
import { useRecoilState } from "recoil";
const Map = () => {
    const [themeMode, setThemeMode] = useRecoilState(thememode);
    const center = useMemo(() => ({ lat: 43, lng: -80 }), []);
    const options = useMemo(() => (
        {
            mapId: themeMode == "dark" ? "5ac90b18c83693c8" : "f2a70983d81f2ccf",
            disableDefaultUI: true,
            clickableIcons: false
        }
    ), [themeMode]);
    return (
        <div className="container m-0 p-0">
            <GoogleMap zoom={10} center={center} options={options} mapContainerClassName="map-container"></GoogleMap>
        </div>
    );
}

export default Map;