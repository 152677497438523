import { CircularProgress } from "@mui/material";

const SplashScreen = () => {
    return ( 
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: 'var(--backgroundColor)' }}>
            <CircularProgress thickness={1.0} size={52} style={{color: "var(--navActiveTextColor"}} />
            <small className="my-2" style={{color: "var(--textLightColor"}} ><i>Please wait ....</i></small>
        </div>
     );
}
 
export default SplashScreen;