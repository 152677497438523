import { useRef, useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';
const Reset = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();

    const userRef: any = useRef();
    const errorRef: any = useRef();

    const [email, setEmail] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);

    const formSubmit = async (e: any) => {
        setErroMessage('')
        setSuccessMessage('')
        setLoading(true)
        e.preventDefault();
        const formData: FormData = new FormData();
        formData.append('email', email);
        let response: any = await api.resetPassword(formData).catch((error: any) => { });
        setLoading(false)
        if (response?.success) {
            setSuccessMessage("Password Reset Successfully")
            navigate(`/reset-password/success/${email}`)
        }
        else {
            setErroMessage(response?.message ?? "Server error, Please try again later")
        }
    }

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [email])

    return (
        <form onSubmit={formSubmit} className={`${api.getTheme() == "light" ? "bg-light" : ""} mx-auto my-4 card-body p-4 rounded`} style={{ width: '430px', backgroundColor: api.getTheme() == "light" ? "" : "var(--backgroundDimmerColor)" }}>
            <div className="text-center mt-2">
                <h5 className="">Password Reset</h5>
                <p style={{ color: "var(--textLightColor)" }} className="">Fill in your email address and submit the form to reset your password.</p>
                {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
            </div>
            <div className='my-4 py-2 d-flex flex-column justify-content-start align-item-start'>
                <TextField inputProps={{ style: { color: "var(--textColor)!important" } }} InputLabelProps={{ style: { color: "var(--textLightColor)" } }} required ref={userRef} variant="standard" value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" className='w-100 my-2' type="email" label="Email Address" />
                <div className='mx-0 my-2 d-flex align-items-center justify-content-end'><Link style={{ color: 'var(--primaryColor)' }} className='text-decoration-none' to="/login">Back to login</Link></div>
                <div className="w-100 d-flex justify-content-end">
                    <Button style={{ backgroundColor: 'var(--primaryColor)' }} disableElevation disabled={load} type="submit" className="py-2 w-50" variant='contained'>
                        {!load ? "Reset Password" : (
                            <CircularProgress size={23} />
                        )}
                    </Button></div>
            </div>
        </form>
    );
}

export default Reset;