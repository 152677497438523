import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/logo.png';
import BackgroundImage1 from '../assets/shape1_1.png';
import BackgroundImage2 from '../assets/shape2_1.png';
import LogoLight from '../assets/hd-logo-light.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';
import { MdMyLocation, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import LoaderIconButton from '../components/loaderIconButton';

const RegisterBusiness = () => {

    const api: any = useApiServices();

    const {uid}: any = useParams();

    const navigate: any = useNavigate();
    const { setAuth }: any = useAuth();

    const nameRef: any = useRef();
    const tradingNameRef: any = useRef();
    const tinRef: any = useRef();
    const locationRef: any = useRef();
    const errorRef: any = useRef();

    const [isLogedin, setIsLogedin] = useRecoilState(logedin)
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [shopName, setShopName] = useState("");
    const [shopTradingName, setTradingName] = useState("");
    const [tin, setTin] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);
    const [isLocationLoading, setIsLocationLoading] = useState(false);

    const formSubmit = async (e: any) => {

        setErroMessage('')
        setSuccessMessage('')
        setLoading(true)
        e.preventDefault();
                
        let formData: FormData = new FormData();
        formData.append("name", shopName);
        formData.append("trading_name", shopTradingName);
        formData.append("tin", tin);
        formData.append("email", email);
        formData.append("location", location);

        let response: any = await api.registerBusiness(formData, uid).catch((e: any) => { setLoading(false) }).catch((error: any) => { setLoading(false) });

        setLoading(false)
        
        if (response?.success) {
            setSuccessMessage(response?.message)
            navigate("/login")
        }
        else {
            setErroMessage(response?.message ?? "Server error, Please try again later")
        }
    }

    const getCurrentLocation = async (e: any) => {
        setIsLocationLoading(true);
        try {

            let location: any = "";

            await navigator.geolocation.getCurrentPosition((position: any) => {
                // console.log(position.coords)
                location = position.coords
            })

            console.log(location)

            // await api.getLocationName()

        } catch {

        } finally {
            setIsLocationLoading(false);
        }
    }

    useEffect(() => {
        nameRef.current.focus()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [shopName, shopTradingName, tin, email, location])

    return (
        <form onSubmit={formSubmit} className={`${api.getTheme() == "light" ? "" : ""} w-100 card-body rounded py-2`} style={{ height: '75%', backgroundColor: api.getTheme() == "light" ? "" : "var(--backgroundDimmerColor)" }}>
            <div className="text-center">
                <h5 className="my-2" style={{ color: 'var(--textColor)' }}>Register</h5>
                <p style={{ color: "var(--textLightColor)" }} className="">Business Setup</p>
                {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
            </div>
            <p aria-live="assertive" style={{ color: "var(--textLightColor)" }}>{"In the future we might need more information about your business"}</p>
            <div className='py-2 px-4 d-flex flex-column justify-content-start align-item-start' style={{ overflowY: 'scroll', flex: 'none', height: '90%' }}>
                <TextField
                        inputProps={{ style: { color: "white" } }}
                        InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                        required
                        ref={nameRef}
                        variant="standard"
                        value={shopName}
                        onChange={(e) => { setShopName(e.target.value) }}
                        name="shop_name"
                        className='w-100 my-2'
                        type="text"
                        label="Shop Name"
                    />
                <TextField
                        inputProps={{ style: { color: "white" } }}
                        InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                        required
                        ref={tradingNameRef}
                        variant="standard"
                        value={shopTradingName}
                        onChange={(e) => { setTradingName(e.target.value) }}
                        name="shop_trading_name"
                        className='w-100 my-2'
                        type="text"
                        label="Shop Trading Name"
                     />
                <TextField
                        inputProps={{ style: { color: "white" } }}
                        InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                        required
                        ref={tinRef}
                        variant="standard"
                        value={tin}
                        onChange={(e) => { setTin(e.target.value) }}
                        name="tin_number"
                        className='w-100 my-2'
                        type="number"
                        label="TIN Number"
                    />
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex justify-content-end w-100">
                        <Button style={{ backgroundColor: 'var(--primaryColor)' }} disabled={load} disableElevation type="submit" className="py-2 w-100 my-2 mx-1" variant='contained'>
                            {!load ? "Finish" : (
                                <CircularProgress size={23} />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default RegisterBusiness;