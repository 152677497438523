import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRecoilState } from 'recoil';
import { thememode } from '../context/atoms';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, TextField } from '@mui/material';
import useApiServices from '../hooks/useAxios';
import { jsPDF } from "jspdf";
import { TableDataParamenters } from '../services/interfaces';

const StyledTableCell = styled(TableCell)(({ theme }) => ({


    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bolder'
    },
    [`&.${tableCellClasses.body}`]: {
        // backgroundColor: theme.palette.primary,
        color: theme.palette.common.white,
        fontSize: 14,
    },
}));



export default function DataTable({ disableSearch = false, disableExport = false, rows, columns, pegination = true }: TableDataParamenters) {
    const [themeMode, setThemeMode] = useRecoilState(thememode);
    const [componentRows, setRows]: any = useState([...rows])
    const [searched, setSearched] = useState("");
    const [initialized, setInitial] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pagedRows, setPagedRows] = useState([]);
    const [pagedAllRows, setPagedAllRows]: any = useState([]);
    const [pageIndex, setPageIndex]: any = useState(1);
    const [pageCount, setPageCount]: any = useState(0);
    const [rowCount, setRowCount]: any = useState(0);
    const [pdfElement, setPdfElement]: any = useState(null);

    const api: any = useApiServices();

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const downloadCSV = (event: any) => {
        let data: any = {};
        data['headers'] = []
        data['rows'] = []
        columns?.forEach((column: any) => {
            if (column?.headerName != "")
                data['headers']?.push(api.capitalizeString(column?.headerName));
        })
        rows?.forEach((row: any, index: number) => {
            data['rows'].push([]);
            columns?.forEach((column: any) => {
                if (column?.headerName != "") {
                    data['rows'][index].push(row[column?.field]);
                }
            });
        })
        api.exportExcel(data);
    };

    const downloadExcel = (event: any) => {
    };

    const downloadPDF = async (event: any) => {
        const pdf = new jsPDF("portrait", "pt");
        const data: any = pdfElement;
        pdf.html(data).then(() => {
            pdf.save("shipping_label.pdf");
        });
    };

    const sync = async (overideRow: any = null) => {
        // requestSearch(searched)
        // console.log(pagedRows.length)
        // if (pageCount == 0) {
        const _rows = overideRow ?? rows;
        const newRow: any = [];
        let nextCell = 0;
        for (var row in _rows) {
            if (newRow[nextCell] == undefined) {
                newRow[nextCell] = [];
                newRow[nextCell].push(_rows[row]);
            } else {
                newRow[nextCell].push(_rows[row])
                if (newRow[nextCell].length == rowsPerPage) {
                    nextCell += 1;
                }
            }
        }
        setPagedRows(newRow[pageIndex - 1]);
        setPagedAllRows(newRow);
        setPageCount(newRow.length);
        setRowCount(_rows?.length)
        // }
    }

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal)
        const filteredRows = rows.filter((row: any) => {
            console.log(row[Object.keys(row)[2]])
            let isIn: boolean = row[Object.keys(row)[1]].toLowerCase().includes(searchedVal.toLowerCase()) || row[Object.keys(row)[2]].toLowerCase().includes(searchedVal.toLowerCase())
            return isIn;
        });
        sync(filteredRows);
    };

    useEffect(() => {
        setPdfElement(document.getElementById("pdf"))
        sync()
    }, [rows])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            color: (themeMode == 'dark') ? theme.palette.common.white : theme.palette.common.black,
            fontWeight: 'bolder'
        },
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: theme.palette.primary,
            color: (themeMode == 'dark') ? theme.palette.common.white : theme.palette.common.black,
            fontSize: 14,
        },
    }));

    return (
        <div className="d-flex flex-column w-100">
            <div className='d-flex justify-content-between my-2'>
                <div className="d-flex flex-column align-items-start">
                    {
                        !disableSearch ? (
                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                <Button onClick={downloadCSV} className="text-capitalize">CSV</Button>
                                {/* <Button onClick={downloadExcel} className="text-capitalize">Excel</Button> */}
                                <Button onClick={downloadPDF} className="text-capitalize">PDF</Button>
                            </ButtonGroup>
                        ) : null
                    }
                </div>
                <div className="d-flex flex-column align-items-start">
                    {
                        !disableSearch ? (
                            <TextField inputProps={{ style: { color: 'var(--textColor)' } }} value={searched} onChange={(event: any) => requestSearch(event.target.value)} label="Search" size='small' focused type='search' />
                        ) : null
                    }
                </div>
            </div>
            <div className='d-none'>
                <table className="w-auto" id='pdf'>
                    <tr>
                        {
                            columns && columns.map((column: any, index: number) => {
                                if (column['hidden']) return null;
                                return (
                                    <th>{column.headerName}</th>
                                )
                            })
                        }
                    </tr>
                    {
                        pagedRows && pagedRows.map((row: any, index: number) => (
                            <tr style={{
                                backgroundColor: index % 2 != 0 ? 'var(--backgroundColor)' : '',
                            }} key={row.name}>
                                {
                                    columns && columns.map((column: any, _index: number) => {
                                        if (column['hidden']) return null;
                                        if (column['field'] == 'action') return null;
                                        if (column['date']) {
                                            return (<td className="border-0" key={_index}>{api.parseDate(row[column.field])}</td>)
                                        }
                                        else {
                                            return (<td className="border-0" key={_index}>{row[column.field]}</td>)
                                        }
                                    })
                                }
                            </tr>
                        ))
                    }
                </table>
            </div>
            <TableContainer className="table-container w-100" component={Paper}>
                <Table sx={{ minWidth: 500 }} className="table" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {
                                columns && columns.map((column: any, index: number) => {
                                    if (column['hidden']) return null;
                                    return (<StyledTableCell className="text-capitalize border-0 fs-10 fw-bold" key={index}>{column.headerName}</StyledTableCell>);
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ border: 'none' }}>
                        {
                            pagedRows && pagedRows.map((row: any, index: number) => (
                                <TableRow style={{
                                    backgroundColor: index % 2 != 0 ? 'var(--backgroundColor)' : '',
                                }} key={row.name}>
                                    {
                                        columns && columns.map((column: any, _index: number) => {
                                            if (column['hidden']) return null;
                                            if (column['date']) {
                                                return (<StyledTableCell className="border-0" key={_index}>{api.parseDate(row[column.field])}</StyledTableCell>)
                                            }
                                            else {
                                                return (<StyledTableCell className="border-0" key={_index}>{row[column.field]}</StyledTableCell>)
                                            }
                                        })
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <small style={{ color: 'var(--textColor)' }}>{rowCount} rows, showing page {pageIndex} of {pageCount} </small>
                    {
                        pegination && (
                            <Pagination
                                onChange={(e: any, value: any) => {
                                    setPagedRows(pagedAllRows[+value - 1])
                                    setPageIndex(+value)
                                }}
                                sx={{ color: 'var(--textColor)', borderColor: 'var(--iconColor)' }}
                                count={pageCount}
                                variant="outlined"
                                shape="rounded" />
                        )
                    }
                </div>
            </TableContainer>
        </div>
    );
}
