import { IconButton, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { RiSaveLine } from "react-icons/ri";

const CardAppBar = ({ canNavigateBack = true, isProcessing = false, title = "Title Prop", subTitle = "Subtitle Prop", backLink = "", children, badge = (<p className="p-0 m-0"></p>), loadingProgressText = null }: any) => {
    return (
        <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                {
                    canNavigateBack && (
                        <Box sx={{ m: 1, position: 'relative' }}>
                            {isProcessing ? (<CircularProgress sx={{ position: 'absolute', top: '0', left: '0' }} style={{ color: 'var(--iconColor)' }} thickness={1.6} />) : null}
                            <Tooltip title={isProcessing ? `Please Wait` : 'Back'}>
                                <Link to={isProcessing ? "" : backLink}>
                                    <IconButton disabled={isProcessing} style={{ color: 'var(--textColor)' }} className="flex-none">
                                        <IoIosArrowBack />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Box>
                    )
                }
                <div className="d-flex flex-column align-items-start p-2 text-nowrap">
                    <p className="fw-bold my-0 py-0 text-capitalize">{title} {badge}</p>
                    <small>{subTitle}</small>
                    {
                        loadingProgressText != null ? (
                            <small><i>{loadingProgressText}</i></small>
                        ) : null
                    }
                </div>
            </div>
            <div className="d-flex w-100 justify-content-end align-items-center">
                {children}
            </div>
        </div>
    );
}

export default CardAppBar;