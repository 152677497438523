import React, { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider } from 'react-bootstrap';
import API from './services/api.services';
import Layout from './layout';
import AdminLayout from './admin-pages/admin-layout';
import UnauthRoutes from './unauth-pages/unauth.routes';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logedin, thememode } from './context/atoms';
import { createTheme } from '@mui/material';
import useApiServices from './hooks/useAxios';
import useRefreshToken from './hooks/useRefreshToken';
import { GiReturnArrow } from 'react-icons/gi';
import SplashScreen from './utilities/splashscreen';

function App() {
  const api = new API();
  const refresh: any = useRefreshToken();

  const [isAuthenticated, setLogedIn] = useRecoilState(logedin);
  const [themeMode, setThemeMode] = useRecoilState(thememode);
  const [isProcessed, setIsProcessed] = useState(true);

  const theme = createTheme({
    typography: {
      fontFamily: [
        'IBM Plex Sans',
        'sans-serif',
      ].join(",")
    },
    palette: {
      primary: {
        light: '#757ce8',
        main: '#f3f8fb',
        dark: '#002884',
        contrastText: '#fff',
      },
      text: {
        primary: 'grey',
        secondary: 'grey',
      },
      mode: (themeMode == 'light') ? 'light' : 'dark',
    },
  });

  const onLoad = async () => {
    setIsProcessed(true)
    const access = await refresh().catch((error: any) => { })
    console.log(access);
    if (access == 'invalid' || access == undefined) {
      // setLogedIn(false);
      setIsProcessed(false)
      return
    }
    setLogedIn(true);
    setIsProcessed(false)
  }

  useEffect(() => { onLoad() }, [])

  return (
    <div className={`App ${themeMode}`}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
        theme={theme}
      > {
          isProcessed == true ? (
            <SplashScreen />
          ) : (
            <BrowserRouter>
              {
                (isAuthenticated) ? (
                  <>
                    {
                      (false) ? (<AdminLayout />) : (<Layout />)
                    }
                  </>
                ) : (<UnauthRoutes />)
              }
            </BrowserRouter>

          )
        }

      </ThemeProvider>
    </div>
  );
}

export default App;
