import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import BackgroundImage1 from '../assets/shape1_1.png';
import BackgroundImage2 from '../assets/shape2_1.png';
import LogoLight from '../assets/hd-logo-light.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
const Register = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();
    const { setAuth }: any = useAuth();

    const firstNameRef: any = useRef();
    const lastNameRef: any = useRef();
    const emailRef: any = useRef();
    const countryRef: any = useRef();
    const phoneRef: any = useRef();
    const usernameRef: any = useRef();
    const passwordRef: any = useRef();
    const passwordConfirmationRef: any = useRef();
    const errorRef: any = useRef();

    const [isLogedin, setIsLogedin] = useRecoilState(logedin)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhoneNo] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(false);

    const formSubmit = async (e: any) => {
        setErroMessage('')
        setSuccessMessage('')
        setLoading(true)
        e.preventDefault();
        
        let formData: FormData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("username", username);
        formData.append("country", country);
        formData.append("phone_number", phone);
        formData.append("password", password);
        formData.append("password_confirmation", passwordConfirmation);

        let response: any = await api.registerOwner(formData).catch((e: any) => { setLoading(false) }).catch((error: any) => { setLoading(false) });

        setLoading(false)
        if (response?.success) {
            setSuccessMessage(response?.message)
            navigate(`business/${response?.data?.uid}`);
        }
        else {
            setErroMessage(response?.message ?? "Server error, Please try again later")
        }
    }

    useEffect(() => {
        firstNameRef.current.focus()
    }, [])

    useEffect(() => {
        setSuccessMessage("")
        setErroMessage("")
    }, [email, password])

    return (
        <form onSubmit={formSubmit} className={`${api.getTheme() == "light" ? "" : ""} w-100 card-body rounded py-2`} style={{ height: '500px', overflowY: 'hidden'}}>
            <div className="text-center">
                <h5 className="my-2" style={{color: 'var(--textColor)'}}>Register</h5>
                <p style={{ color: "var(--textLightColor)" }} className="">Fugas Owner registration.</p>
                {errorMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="error">{errorMessage}</Alert>) : null}
                {successMessage != '' ? (<Alert aria-live="assertive" ref={errorRef} severity="success">{successMessage}</Alert>) : null}
            </div>
            <div className='py-2 px-4 d-flex flex-column justify-content-start align-item-start' style={{ overflowY: 'scroll', flex: 'none', height: '90%' }}>
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={firstNameRef}
                    variant="standard"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                    name="first_name"
                    className='w-100 my-2'
                    type="text"
                    label="First Name"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={lastNameRef}
                    variant="standard"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value) }}
                    name="last_name"
                    className='w-100 my-2'
                    type="text"
                    label="Last Name"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={emailRef}
                    variant="standard"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    name="email_address"
                    className='w-100 my-2'
                    type="email"
                    label="Email Address"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={usernameRef}
                    variant="standard"
                    value={username}
                    onChange={(e) => { setUsername(e.target.value) }}
                    name="username"
                    className='w-100 my-2'
                    type="text"
                    label="Username"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={countryRef}
                    variant="standard"
                    value={country}
                    onChange={(e) => { setCountry(e.target.value) }}
                    name="country"
                    className='w-100 my-2'
                    type="text"
                    label="Country"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={phoneRef}
                    variant="standard"
                    value={phone}
                    onChange={(e) => { setPhoneNo(e.target.value) }}
                    name="phone"
                    className='w-100 my-2'
                    type="text"
                    label="Phone No (+255000000000)"
                />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={passwordRef}
                     variant="standard"
                     value={password}
                     onChange={(e) => { setPassword(e.target.value) }}
                     name="password"
                     className='w-100 my-2'
                     type="password"
                     label="Password"
                    />
                <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "var(--textLightColor)" } }}
                    required
                    ref={passwordConfirmationRef}
                     variant="standard"
                     value={passwordConfirmation}
                     onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                     name="password_confirmation"
                     className='w-100 my-2'
                     type="password"
                     label="Confirm Password"
                    />
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex justify-content-end w-100"><Button style={{ backgroundColor: 'var(--primaryColor)' }} disabled={load} disableElevation type="submit" className="py-2 w-100 my-2 mx-1" variant='contained'>
                        {!load ? "Next" : (
                            <CircularProgress size={23} />
                        )}
                    </Button></div>
                    <div className="d-flex justify-content-end w-100">
                        <Link to="/login" className='w-100'>
                            <Button style={{ backgroundColor: 'var(--primaryColor)' }} disabled={load} disableElevation className="py-2 w-100 my-2 mx-1" variant='contained'>
                                Already have an account
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Register;