import { GiHamburgerMenu } from 'react-icons/gi';
import { MdDarkMode, MdLightMode} from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { Button, Chip, Divider, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ActiveNavLink, logedin, NavExpandedState, profile, systemGlobalLoadingIndicator, systemGlobalToastMessage, thememode } from "../context/atoms";
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useApiServices from '../hooks/useAxios';
import { BiLogOut } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';

const Appbar = ({user=null}:any) => {

    const api: any = useApiServices();
    const navigate: any = useNavigate();

    const [navExpanded, setNavExpanded] = useRecoilState(NavExpandedState);
    const [globalLoadingIndicator, setGlobalLoadingIndicator]: any = useRecoilState(systemGlobalLoadingIndicator);
    const [globalToastMessage, setGlobalToastMessage]: any = useRecoilState(systemGlobalToastMessage);
    const [isLogedin, setIsLogedIn] = useRecoilState(logedin);
    const [themeMode, setThemeMode] = useRecoilState(thememode);
    const [activeNavLink, setActiveNavLink] = useRecoilState(ActiveNavLink);
    const [profileImage, setProfileImage] = useRecoilState<any>(profile)
    const [loading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [successMessage, setSuccessMessage]: any = useState(null)

    const errorHandler = (error: any) => {
        setMessage(error.message)
    }

    const sync = async () => {
    }

    useEffect(() => {
        sync()
    })

    useEffect(() => {
        api.setTheme(themeMode);
    }, [themeMode])

    const handleSnackbarClose = (event: any = null, reason: any = null) => {
        if (reason === 'clickaway') {
            return;
        }
        setGlobalToastMessage({
            error: false,
            message: ''
        })
    }

    const [anchorEl, setAnchorEl]: any = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any, link: any) => {
        console.log(link);
        setActiveNavLink(link);
        navigate(link)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = async () => {
        let response = await api.logout();
        if(response?.success){
            navigate(0);
        }
        navigate(0);
        handleClose();
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={globalToastMessage.message != ''}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
                message={globalToastMessage.message}
                // className="position-absolute"
                style={{ top: '10px' }}
            >
                <Alert onClose={handleSnackbarClose} severity={globalToastMessage.error ? "error" : "success"} sx={{ width: '100%' }}>
                    {globalToastMessage.message}
                </Alert>
            </Snackbar>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{ top: '43px', backgroundColor: 'var(--backgroundDimmerColor)' }}
            >
                <div className="p-2 d-flex flex-column justify-content-center align-items-center position-relative">
                    <div style={{ color: 'var(--iconColor)', border: '1px solid  var(--backgroundDimmerColor)', borderRadius: '100%', backgroundImage: `url(${api.avatar})`, backgroundSize: '75%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', outline: '2px dashed var(--iconColor)', outlineOffset: '2.5px' }} className='p-4 mx-2'>
                        {api.avatar ? (<div className="p-2" />) : (<FaUser size={19} />)}
                    </div>
                    <Chip title={api.email} label={api.email} size="small" className="my-2 w-auto" sx={{ color: 'var(--textCOlor)', backgroundColor: 'var(--backgroundDimmerColor)' }} variant='outlined' />
                    <div className="position-absolute end-0" style={{ color: 'var(--iconColor)', border: '2.5px solid  var(--backgroundLightColor)', backgroundColor: 'var(--backgroundDimmerColor)', height: '18px', width: '18px', top: '-5px', borderRadius: '100%' }} />
                </div>
                <Divider />
                <MenuItem onClick={(event:any)=>{handleClick(event, '/profile')}}>
                        <ListItemIcon>
                            <FiSettings fontSize="small" />
                        </ListItemIcon>
                        My Profile
                </MenuItem>
                <MenuItem onClick={(event:any)=>{handleClick(event, '/settings')}}>
                        <ListItemIcon>
                            <FiSettings fontSize="small" />
                        </ListItemIcon>
                        Settings
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <BiLogOut fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <div className="appbar d-flex align-items-center justify-content-between px-4">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <IconButton onClick={(e) => setNavExpanded(!navExpanded)} style={{ color: 'var(--iconColor)', border: '0px solid  var(--backgroundDimmerColor)' }} className='py-2 py-0'>
                        <GiHamburgerMenu size={19} />
                    </IconButton>
                    <h6 style={{ color: "var(--textColor)" }} className="my-auto mx-4 text-capitalize">{(activeNavLink == '/') ? 'Dashboard' : activeNavLink.slice(1).replaceAll("-", " ").split("/")[0]}</h6>
                </div>
                <div className="d-flex">
                    <IconButton onClick={(e) => {
                        if (themeMode == 'light') { setThemeMode('dark') }
                        else { setThemeMode('light') }
                        console.log(themeMode);
                    }} style={{ color: 'var(--iconColor)', border: '1px solid  transparent' }} className='p-2 mx-2'>
                        {
                            (themeMode == 'light') ? (<MdDarkMode size={19} />) : (<MdLightMode size={19} />)
                        }
                    </IconButton>
                    <div className='position-relative'>
                        <IconButton onClick={(e: any) => setAnchorEl(!anchorEl)} style={{ color: 'var(--iconColor)', border: '1px solid var(--backgroundDimmerColor)', backgroundImage: `url(${profileImage})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', outline: '1px dashed var(--iconColor)', outlineOffset: '2.5px' }} className='p-2 mx-2'>
                            {
                                profileImage ? (<div className="p-2" />) : (<FaUser size={19} />)
                            }
                        </IconButton>
                        <div className="position-absolute end-0" style={{ color: 'var(--iconColor)', border: '2.5px solid  var(--backgroundLightColor)', backgroundColor: 'var(--backgroundDimmerColor)', height: '18px', width: '18px', top: '-5px', borderRadius: '100%' }} />
                    </div>
                </div>
            </div>
            {
                (globalLoadingIndicator) ? (
                    <LinearProgress sx={{
                        height: '2.0px',
                        backgroundColor: 'transparent'
                    }}
                    color='warning'
                    variant='indeterminate'
                    />
                ) : null
            }
        </>
    );
}

export default Appbar;