import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import CardAppBar from "../../components/cardAppbar";
import Users from "./users";
import LoaderButton from "../../components/loaderButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../components/sortableTable";
import Invites from "./invites";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Settings = () => {

  let navs: any = [
    "preference",
    "user",
  ]

  let userNavs: any = [
    "users",
    "invites",
  ]

  const navigate = useNavigate();
  const location = useLocation();

  let route: any = location.pathname.split("/")
  route = route[route.length - 1]
  let routeIndex = navs.indexOf(route)
  const [value, setValue] = useState(routeIndex == -1 ? 0 : routeIndex);
  const [userViewIndex, setUserViewIndex] = useState(routeIndex == -1 ? 0 : routeIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/settings/${navs[newValue]}`)
    setValue(newValue);
  };

  const handleUserViewChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/settings/user/${userNavs[newValue]}`)
    setUserViewIndex(newValue);
  };

  return (
    <div className="custom-card m-3 d-flex flex-column">
      <div className="d-flex flex-column w-100 justify-content-between align-items-start">
        <CardAppBar

          title="Settings"
          subTitle="Fugas Settings & Preferences"
          canNavigateBack={false}
          isProcessing={false}
        >
        </CardAppBar>
        <div className="d-flex align-items-center py-2 w-100">

          <Box sx={{ width: '100%',}}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', p: '0px' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Preferences" {...a11yProps(0)} />
                <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Users" {...a11yProps(1)} />
                <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Advanced" {...a11yProps(2)} />
                <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Integration" {...a11yProps(3)} />
                {value == 1 ? (<div className="position-absolute end-0" ><Link to="/settings/user/registration" className="text-decoration-none link-unstyled"><LoaderButton text="New User" isLoading={false} toolTip={'Register New User'} /></Link></div>) : null}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              {/* <DataTable columns={[]} rows={[]} /> */}
            </TabPanel>

            <TabPanel value={value} index={1}>

              <Box  style={{border: 'none'}} className="w-100" sx={{ borderBottom: 1, borderColor: 'divider', p: '0px', m: '0' }}>
                <Tabs value={userViewIndex} onChange={handleUserViewChange} aria-label="basic tabs example">
                  <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Users" {...a11yProps(0)} />
                  <Tab sx={{ color: 'var(--textColor)', textTransform: 'capitalize' }} label="Invites" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <TabPanel value={userViewIndex} index={0}>
                <Users />
              </TabPanel>

              <TabPanel value={userViewIndex} index={1}>
                <Invites />
              </TabPanel>

            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Settings;