import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { MdDelete, MdVerified } from "react-icons/md";
import { RiErrorWarningFill, RiIndeterminateCircleFill } from "react-icons/ri";

const UserBadge = ({ isVerified, isTerminated, isArchived }: any) => {

    let isFullyActive: boolean = isVerified && !isTerminated && !isArchived;
    let isTerminatedActive: boolean = isTerminated;
    let isDeleted: boolean = isArchived;
    let isEmailVerified: boolean = isVerified;

    let returned: any;

    useEffect(()=>{
        console.log(`${isVerified} ${!isTerminated} && ${!isArchived}`)
    }, [])

    if (isFullyActive) {
        returned = (
            <MdVerified className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'blue', backgroundColor: 'white', borderRadius: '100%' }} size={34} />
        );
    }

    else if (!isEmailVerified) {
        returned = (
            <Tooltip title={`This user has not completed registration or the email is invalid`}>
                <RiErrorWarningFill className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'red', backgroundColor: 'white', borderRadius: '100%' }} size={34} />
            </Tooltip>
        );
    }

    else if (isTerminatedActive) {
        returned = (
            <Tooltip title={`This user has been terminated`}>
                <RiIndeterminateCircleFill className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'red', backgroundColor: 'white', borderRadius: '100%' }} size={34} />
            </Tooltip>
        );
    }

    else if (isDeleted) {
        returned = (
            <Tooltip title={`This user has been deleted`}>
                <MdDelete className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'red', backgroundColor: 'white', borderRadius: '100%' }} size={34} />
            </Tooltip>
        );
    }

    return returned || "•";
}

export default UserBadge;




// {
//     userData?.active ? (<MdVerified className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'blue', backgroundColor: 'white', borderRadius: '100%' }} size={34} />) : null
// }
// {/* User Is Not Active */}
// {
//     !userData?.active ? (
//         <Tooltip title={`${userData?.first_name} has not completed registration or the email is invalid`}>
//             <RiErrorWarningFill className="p-1" style={{ position: 'absolute', top: '5px', right: '5px', color: 'red', backgroundColor: 'white', borderRadius: '100%' }} size={34} />
//         </Tooltip>
//     ): null
// }