import { useEffect, useState } from "react";
import DataTable from "../../components/table";
import useApiServices from "../../hooks/useAxios";
import Avatar from '@mui/material/Avatar';
import LoaderIconButton from "../../components/loaderIconButton";
import { MdDelete } from "react-icons/md";
import { FaWalking } from "react-icons/fa";
import { BsPersonLinesFill } from "react-icons/bs";
import useModal from "../../hooks/useModalHook";
import useWidgetModal from "../../hooks/useWidgetModalHook";
import UserDetails from "./users/details";

const columns = [
    { field: 'no', headerName: '#', sortable: true },
    { field: 'avatar', headerName: 'Avatar' },
    { field: 'username', headerName: 'Username' },
    { field: 'email', headerName: 'Email' },
    { field: 'last_login', headerName: 'Last Login', date: true },
    { field: 'created_at', headerName: 'Created At', date: true },
    { field: 'actions', headerName: '' },
];

const Users = () => {

    const api = useApiServices();

    const [tableData, setTableData] = useState([])
    const confirmationModal = useModal();
    const widgetModal = useWidgetModal();

    // ///////////////////////////////////////////////////////////// //

    const deleteUserInvite: any = async (inviteId: number) => {
        await api.cancelUserInvite(inviteId).catch((error: any) => { });
    }

    // ///////////////////////////////////////////////////////////// //

    const sync = async () => {
        let users: any = await api.getUsers();
        let tableData: any = [];
        users = users.data;
        users?.forEach((user: any, index: number) => {
            let isCurrent: any = api.email == user['email']
            let currentUser: any = isCurrent ? "(You)" : ""
            tableData.push({
                no: index += 1,
                avatar: (
                    <Avatar
                        alt={`${user['first_name']} ${user['last_name']}`}
                        src={`${api.host}/media/${user['avatar']}`}
                        sx={{ width: 34, height: 34 }}
                    />
                ),
                username: `${user['username']} ${currentUser}`,
                email: user['email'],
                last_login: user['last_seen'],
                created_at: user['created_at'],
                actions: isCurrent ? null : (
                    <div className="d-flex justify-content-end">
                        <LoaderIconButton icon={<BsPersonLinesFill size={20} />} onClick={(e: any) => {
                            widgetModal({
                                title: `${user['first_name']} Details`,
                                children: (
                                    <UserDetails userId={user['id']} />
                                )
                            })
                        }} toolTip={"View Profile"} />
                        <LoaderIconButton icon={<FaWalking size={20} />} onClick={(e: any) => {
                            widgetModal({
                                title: `${user['first_name']} Login Attempts`,
                                children: (
                                    <UserDetails userId={user['id']} />
                                )
                            })
                        }} toolTip={"View Profile"} />
                        <LoaderIconButton icon={<MdDelete size={20} />} onClick={(e: any) => {
                            confirmationModal({
                                title: "Confirmation",
                                message: `You're about to delete ${user['username']} from the system, click \`Ok\` to perform the action. `,
                                onConfirm: async () => { },
                                onCancel: async () => { }
                            })
                        }} toolTip={"Delete Profile"} />
                    </div>
                ),
            })
        })
        setTableData(tableData)
    }

    // ///////////////////////////////////////////////////////////// //
    useEffect(() => {
        sync();
        // ///////////////////////////////////////////////////////////// //
        return () => {
            api.abortRequest();
        }
    }, [])

    return (
        <div className="">
            <DataTable columns={columns} rows={tableData} />
        </div>
    );
}

export default Users;