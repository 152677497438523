import { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import Navigation, { AdminNavigation } from "../services/navigation.links";
import { VscSettings } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ActiveNavLink, NavExpandedState, thememode } from "../context/atoms";
import Collapsable from "../components/collapsable";
import Logo from '../assets/logo.png';
import LogoSm from '../assets/hd-logo-sm-dark.png';
import LogoDark from '../assets/hd-logo-light.png';
import LogoSmDark from '../assets/hd-logo-sm-light.png';
import { MdAdminPanelSettings } from 'react-icons/md';
import useApiServices from '../hooks/useAxios';

const SideNav = ({ user = null }: any) => {

    const api: any = useApiServices();

    const [navExpanded, setNavExpanded] = useRecoilState(NavExpandedState);
    const [activeNavLink, setActiveNavLink] = useRecoilState(ActiveNavLink);
    const [theme, setThememode] = useRecoilState(thememode);
    const [navigations, setNavigations] = useState<any>(user ? AdminNavigation() : Navigation());

    return (
        <nav className={`side d-flex flex-column overflow-hidden ${navExpanded ? 'active' : ''}`}>
            <div className="logo mt-4" style={{ color: 'var(--iconColor)', fill: 'var(--iconColor)' }}>
                {
                    (theme == "light") ?
                        (navExpanded) ? (
                            <img width={40} src={Logo} alt="" />
                        ) : (
                            <div>
                                <MdAdminPanelSettings style={{ color: 'var(--iconColor)' }} size={29} className='mx-2' />
                            </div>
                        )
                        : (navExpanded) ? (
                            <img width={40} src={Logo} alt="" />
                        ) : (
                            <div>
                                {/* <h4 className="text-light">SR</h4> */}
                                <MdAdminPanelSettings style={{ color: 'var(--iconColor)' }} size={29} className='mx-2' />
                            </div>
                            // <img width={19} src={LogoSmDark} alt="" />
                        )
                }
            </div>
            <ul className="navigation py-2 scrollable-y h-100 my-0 list-unstyled d-flex flex-column justify-content-start align-items-center">
                {
                    navigations.map((nav: any, index: number) => {
                        if (nav.parent)
                            return (
                                <Collapsable key={index} title={nav.name} defaultOpen={nav.defaultOpen}>
                                    {nav.children.map((child: any, i: number) => {
                                        if (!child.label && !child.parent && child.clickable) {
                                            return (<li key={child.label + child.link} className="w-100" style={{ textDecoration: 'none' }}>
                                                <Link key={child.label + `${i}${index}`} to={child.link} onClick={(e) => { setActiveNavLink(child.link) }} className="w-100 text-decoration-none">
                                                    <Button key={child.link + `${i}${index}`} className={`nav-button p-2 px-4 mx-auto d-flex flex-row align-items-center ${(activeNavLink == child.link ? "active" : '')} ${navExpanded ? 'justify-content-start' : 'justify-content-center'} w-100`} style={{ fontSize: '10pt' }}>
                                                        {child.icon} {child.label}
                                                        <p key={child.link + `${index}${i}`} className={`mx-2 my-0 text-nowrap opacity-100 text-capitalize ${!navExpanded ? 'd-none' : ''}`}>{child.name}</p>
                                                    </Button>
                                                </Link>
                                            </li>)
                                        }

                                        return null
                                    })}
                                </Collapsable>
                            )
                        return nav.clickable && nav.permissions.includes(api.getUserType()) ? (<li key={index} className="w-100 my-2" style={{ textDecoration: 'none' }}>
                            <Link key={nav.label} to={nav.link} onClick={(e) => { setActiveNavLink(nav.link) }} className="w-100 text-decoration-none">
                                <Button key={nav.link} className={`nav-button p-2 px-3 w-100 mx-auto d-flex flex-row align-items-center ${(activeNavLink == nav.link ? "active" : '')} ${navExpanded ? 'justify-content-start' : 'justify-content-center'} w-100`} style={{ fontSize: '10pt' }}>
                                    {nav.icon} {nav.label}
                                    <p key={nav.link + `-${index}`} className={`mx-2 my-0 text-nowrap opacity-100 text-capitalize ${!navExpanded ? 'd-none' : ''}`}>{nav.name}</p>
                                </Button>
                            </Link>
                        </li>) : null
                    })
                }
                {/* {
                    Navigation().map((nav: any) => (
                        <div className="w-100">
                            {
                                nav && nav.label && navExpanded && nav.clickable ? (
                                    <p className="fw-bold my-1 py-2 px-4 d-flex justify-content-start align-items-center text-uppercase" style={{color: 'var(--textColor)', fontSize: '9pt'}}>{nav.name}</p>
                                ) : !nav.label && !nav.parent && nav.clickable ? (
                                    <li className="w-100" style={{ textDecoration: 'none' }}>
                                        <Link to={nav.link} onClick={(e) => { setActiveNavLink(nav.link) }} className="w-100 text-decoration-none">
                                            <Button className={`nav-button p-2 px-4 mx-auto d-flex flex-row align-items-center ${(activeNavLink == nav.link ? "active" : '')} ${navExpanded ? 'justify-content-start' : 'justify-content-center'} w-100`}>
                                                {nav.icon} {nav.label}
                                                <p className={`mx-2 my-0 text-nowrap opacity-100 text-capitalize ${!navExpanded ? 'd-none' : ''}`}>{nav.name}</p>
                                            </Button>
                                        </Link>
                                    </li>
                                ) : null
                            }
                        </div>
                    )
                    )
                } */}

            </ul>
            <div className="bottom my-4">
                <Link to="/settings">
                    <Button style={{ color: 'var(--iconColor)' }} className='p-2'>
                        <VscSettings size={19} />
                        {/* <p>{nav.name}</p> */}
                    </Button>
                </Link>
            </div>
        </nav>
    );
}

export default SideNav;