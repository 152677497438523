import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/logo-dark.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';
import { FiCheckCircle } from 'react-icons/fi';
import { RiMailCheckFill } from 'react-icons/ri';

const PasswordSentToEmail = () => {

    const { token, email }: any = useParams();

    return (
        <div className='bg-light mx-auto my-4 card-body p-4 rounded d-flex flex-column justify-content-center align-items-center' style={{ maxWidth: '430px', height: 'auto' }}>
            <RiMailCheckFill className='text-success' size={100} />
            <p className='p-0 m-0 text-success fs-4 my-2'><b>Password Reset Email Sent!</b></p>
            <small><i>A password reset email has been sent to your email address <b>{email}</b></i></small>
            <Link to="/login">
                <Button>Back To Login</Button>
            </Link>
        </div>
    );
}

export default PasswordSentToEmail;