import Button from "@mui/material/Button/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import { RiSaveLine } from "react-icons/ri";

const LoaderButton = ({text="No text", isLoading=false, onClick=()=>{}, toolTip="Tooltip Prop"}:any) => {
    return (
        <Box sx={{ m: 1, position: 'relative' }} className="d-flex justify-content-end">
            {isLoading ? (<CircularProgress sx={{ position: 'absolute', top: '0', left: '0' }} style={{ color: 'var(--iconColor)' }} thickness={1.6} />) : null}
            <Tooltip title={isLoading ? '' : toolTip}>
                <Button disabled={isLoading} onClick={onClick} style={{ color: 'var(--textColor)' }} className="flex-none text-capitalize">
                    {text}
                </Button>
            </Tooltip>
        </Box>
    );
}

export default LoaderButton;