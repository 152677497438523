import { useRef, useState, useEffect } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/logo-dark.png';
import CircularProgress from '@mui/material/CircularProgress';
import { logedin } from '../context/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '../hooks/useAuth';
import useApiServices from '../hooks/useAxios';
import Alert from '@mui/material/Alert';

const VerifyAccount = () => {

    const api: any = useApiServices();

    const navigate: any = useNavigate();
    const { token } = useParams();

    const [isLogedin, setIsLogedin] = useRecoilState(logedin)
    const [errorMessage, setErroMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [load, setLoading] = useState(true);

    const sync = async () => {
        setLoading(true)
        let response: any = await api.verifyAccount(token).catch((error: any) => { });
        if (response?.success) {
            navigate(`/create-password/${response?.identifier}/${token}`)
            console.log(response);
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        sync()
        return () => {

        }
    }, [])

    return (
        <div className='bg-light mx-auto my-4 card-body p-4 rounded' style={{ maxWidth: '430px', height: 'auto' }}>
            <CircularProgress thickness={1.6} />
            <p className='p-0 m-0'><b>Verifying Your Account</b></p>
            <small><i>Please wait....</i></small>
        </div>
    );
}

export default VerifyAccount;