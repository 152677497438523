import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Logo from '../assets/logo.png';
import BackgroundImage1 from '../assets/shape1_1.png';
import BackgroundImage2 from '../assets/shape2_1.png';
import LogoLight from '../assets/hd-logo-light.png';
import Login from './login';
import Recover from './recover';
import Reset from './reset';
import VerifyAccount from './verify';
import CreatePassword from './create-password';
import PasswordSentToEmail from './password-sent-to-email';
import useApiServices from '../hooks/useAxios';
import Register from './register';
import RegisterBusiness from './register-business';

const UnauthRoutes = () => {

    const api: any = useApiServices();


    useEffect(() => {

    })

    return (
        <div className="d-flex align-items-center justify-content-center h-100">
            <div className="w-100 h-100 d-flex flex-row-reverse justify-content-between align-items-center" style={{ backgroundColor: api.getTheme() == "light" ? 'var(--backgroundColor)' : "var(--backgroundColor)" }}>
                <div className="my-auto d-flex flex-column justify-content-center align-items-center mx-4" style={{ zIndex: '10', width: '430px', boxShadow: '1px 1px 10px 1px #0101017f', backgroundColor: 'var(--backgroundLightColor)' }}>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/register' element={<Register />} />
                        <Route path='/register/business/:uid' element={<RegisterBusiness />} />
                        <Route path='/verify/:token' element={<VerifyAccount />} />
                        <Route path='/create-password/:identifier/:token' element={<CreatePassword />} />
                        <Route path='/reset-password' element={<Reset />} />
                        <Route path='/set-password/:token/:email' element={<Recover />} />
                        <Route path='/reset-password/success/:email' element={<PasswordSentToEmail />} />
                        <Route path='/*' element={<Login />} />
                    </Routes>
                </div>
                <div className='w-100 my-4 py-4 d-flex flex-column justify-content-center align-items-end position-absolute bottom-0'>
                    <small style={{ color: "var(--textLightColor)", width: '430px' }}>© 2023 Fugas. Nexent</small>
                </div>
                <div className="p-1 h-100 w-75 position-absolute start-0 d-flex align-items-center justify-content-center bg-light">
                    <img src={Logo} alt="Fugas Logo" style={{height: '18%'}} />
                </div>
            </div>
        </div >
    );
}

export default UnauthRoutes;