import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import API from "../services/api.services";
import { useRecoilState } from "recoil";
import { logedin, systemGlobalLoadingIndicator, systemGlobalToastMessage } from "../context/atoms";
// import { useNavigate } from "react-router-dom";

const apiServices: API = new API();

const useApiServices = () => {
    const refresh = useRefreshToken();
    const { auth }: any = useAuth();
    const setGlobalLoadingIndicator: any = useRecoilState(systemGlobalLoadingIndicator)[1];
    const setGlobalToastMessage: any = useRecoilState(systemGlobalToastMessage)[1];
    const [isLogedIn, setIsLogedin] = useRecoilState(logedin)


    ///////////////////////////////////////////////////////////////////////////////////// //
    
    useEffect(()=>{

        const requestIntercept: any = apiServices.axiosInstance.interceptors.request.use(
            async (config: any) => {
                if(!config?.headers['Authorization']){
                    const newAccessToken = auth.access == undefined ? await refresh(): auth.access;
                    config.headers['Authorization'] = `Bearer ${newAccessToken}`
                }
                setGlobalLoadingIndicator(true)
                return config;
            },
            (error: any) => {
                setGlobalLoadingIndicator(false)
                setGlobalToastMessage({
                    'error': true,
                    'message': error?.message
                })
                return Promise.reject(error)
            }
        )

        const responseIntercept: any = apiServices.axiosInstance.interceptors.response.use(
            async (response: any) => {
                const newAccessToken = auth.access == undefined ? await refresh(): auth.access;
                response.headers['Authorization'] = `Bearer ${newAccessToken}`;
                setGlobalLoadingIndicator(false);
                setGlobalToastMessage({
                    'error': !response?.data?.success || false,
                    'message': response?.data?.message || "Done"
                })
                return response;
            },
            async (error: any) => {
                const previous = error?.config;
                if((error?.response?.status === 403 || error?.response?.status === 401) && !previous?.sent){
                    previous.sent = true;
                    const newAccessToken = await refresh();
                    previous.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return apiServices.axiosInstance(previous)
                } else if (error?.response?.status != 403 && error?.response?.status != 401){
                    setGlobalLoadingIndicator(false);
                    setGlobalToastMessage({
                        'error': true,
                        'message': error?.message
                    })
                    return Promise.reject(error);
                }
                // setIsLogedin(false)
                return Promise.reject(error);
            }
        )
        // ////////////////////////////////////////// //
        return () => {
            apiServices.axiosInstance.interceptors.request.eject(requestIntercept)
            apiServices.axiosInstance.interceptors.response.eject(responseIntercept)
            apiServices.abortRequest();
        }
    }, [auth, refresh])
    
    // ////////////////////////////////////////////// //
    return apiServices;
}
 
export default useApiServices;