import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdOutlineUpdate } from "react-icons/md";
import { Link } from "react-router-dom";
import CardAppBar from "../../components/cardAppbar";
import LoaderIconButton from "../../components/loaderIconButton";
import DataTable from "../../components/table";
import useApiServices from "../../hooks/useAxios";
import useModal from "../../hooks/useModalHook";

const columns = [
    { field: 'no', headerName: '#', sortable: true },
    { field: 'created_at', headerName: 'Created Date', date: true },
    { field: 'trading_name', headerName: 'Trading Name' },
    { field: 'name', headerName: 'Shop Name' },
    { field: 'location', headerName: 'Location' },
    { field: 'actions', headerName: '' },
];

const Shop = () => {
    const api: any = useApiServices();

    const modal = useModal();

    const [stores, setStores] = useState([]);

    const sync = async () => {
        let _stores: any = await api.getShops();
        let tempStore: any = []
        if (_stores.success) {
            _stores.data.forEach((store: any, index: number) => {
                tempStore.push({
                    "no": index + 1,
                    "name": `${store['name']}`,
                    "trading_name": `${store['trading_name']}`,
                    "location": `${store['location']}`,
                    "created_at": `${store['created_at']}`,
                    "actions": (
                        <>
                            <Tooltip title={`Update ${store['name']}`} className="mx-2">
                                <IconButton onClick={() => { }}><MdOutlineUpdate size={20} style={{ color: 'var(--textColor)' }} /></IconButton>
                            </Tooltip>
                            <Tooltip title={`Delete ${store['name']}`} className="mx-2">
                                <IconButton onClick={() => {
                                    modal({
                                        title: "Confirmation",
                                        message: `Are you sure you want to delete ${store['name']} from the system ?`,
                                        onConfirm: async () => {
                                            await confirmDelete({ storeId: store['id'] })
                                        },
                                        onCancel: async () => { }
                                    })
                                }}><MdDelete size={20} style={{ color: 'var(--textColor)' }} /></IconButton>
                            </Tooltip>
                        </>
                    ),
                })
            })
            setStores(tempStore)
        }
    }

    const confirmDelete = async ({ storeId }: any) => {
        try {
            await api.deleteStore(storeId);
            sync()
        } catch (e) { }
        return storeId
    }

    useEffect(() => {
        sync();
        return () => {
            api.abortRequest();
        }
    }, [])


    return (
        <div className="custom-card m-3 d-flex flex-column">
            <div className="d-flex w-100 justify-content-between align-items-center">
                <CardAppBar
                    title={"Shops"}
                    subTitle="List of all shops"
                    canNavigateBack={false}
                >
                    <Link to="create">
                        <LoaderIconButton toolTip={"Create Store"} icon={<BsPlusLg />} />
                    </Link>
                </CardAppBar>
            </div>
            <div className="d-flex my-4">
                <DataTable columns={columns} rows={stores} />
            </div>
        </div>
    );
}

export default Shop;