import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

export const CustomTextField = ({ size="medium", className="", required=false, helperText="", disabled=false, multiline=false, errorField, onChange, label, type = "text" }: any) => {
    return (
        <TextField
            size={size}
            disabled={disabled}
            type={type}
            multiline={multiline}
            focused={true}
            helperText={helperText}
            // InputLabelProps={{style: { color: 'var(--textColor)' }}}
            sx={{ borderColor: 'white' }}
            inputProps={{ style: { color: "var(--textColor)" } }}
            InputLabelProps={{ style: { color: "var(--textColor)" } }}
            onChange={onChange}
            className={`my-2 ${className}`}
            value={errorField}
            error={errorField == null || errorField == ""}
            label={label}
            required={required}
            FormHelperTextProps={{style: {color: 'var(--textColor)'}}}
        />
    );
}

export const CutomAutoComplete = ({ variant="outlined", size="medium", defaultSelectedValue, disabled=false, multiple = false, errorField, onChange, label, list = [] }: any) => {
    return (
        <Autocomplete
            disabled={disabled}
            multiple={multiple}
            className="my-2 w-100 text-left"
            size={size}
            disablePortal
            onChange={onChange}
            id="combo-box-demo"
            options={list}
            value={defaultSelectedValue}
            sx={{ width: 300, color: 'var(--textColor)', textAlign: 'left' }}
            getOptionLabel={(option: any) => option.label ? option.label : ''}
            ChipProps={{style: {color: 'var(--textColor)', backgroundColor: 'var(--backgroundDimmerColor)'}}}
            renderInput={(params: any) => <TextField {...params}
                variant={variant}
                value={errorField}
                inputProps={{style: {color: 'var(--textColor)'}, ...params.inputProps}}
                InputLabelProps={{ style: { color: "var(--textColor)" } }}
                focused
                className="text-left"
                error={errorField == null || errorField.length == 0}
                label={label} />}
        />
    );
}

export const IconTextField = ({ disabled=false, errorField, onChange, label, type = "text", icon="$" }: any) => {
    return (
        <FormControl sx={{ m: 1 }} variant="standard">
          <InputLabel sx={{ color: "var(--textColor)" }} htmlFor="standard-adornment-amount">{label}</InputLabel>
          <Input
            type={type}
            disabled={disabled}
            onChange={onChange}
            error={errorField == null || errorField.length == 0}
            inputProps={{ style: { color: "var(--textColor)" } }}
            id="standard-adornment-amount"
            startAdornment={<InputAdornment sx={{ color: 'var(--iconColor)'}} position="start">{icon}</InputAdornment>}
          />
        </FormControl>
    );
}

export default [CustomTextField, CutomAutoComplete];