import { CircularProgress, Tooltip } from "@mui/material";

import { useEffect, useState } from 'react';
import useApiServices from '../../../hooks/useAxios';
import { MdDelete, MdPassword, MdVerified } from "react-icons/md";
import { RiErrorWarningFill, RiIndeterminateCircleFill, RiLogoutCircleFill } from "react-icons/ri";
import UserBadge from "./badge";
import LoaderIconButton from "../../../components/loaderIconButton";

const UserDetails = ({ userId }: any) => {

    // ////////////////////////////////////////////////////////////////////////////////////// //

    const api = useApiServices();

    const [isLoading, setIsLoading] = useState<any>(null);
    const [userData, setUserData] = useState<any>(null);

    // ////////////////////////////////////////////////////////////////////////////////////// //

    const sync = async () => {
        setIsLoading(true)
        const response = await api.getUser(userId).catch((error: any) => { });
        setUserData(response?.data);
        setIsLoading(false)
    }

    // ////////////////////////////////////////////////////////////////////////////////////// //

    useEffect(() => {
        sync()
        return () => {
            setIsLoading(null)
        }
    }, [])

    // ////////////////////////////////////////////////////////////////////////////////////// //
    return !isLoading ? (
        <div className="p-2 w-100 h-100 d-flex flex-column">
            <div className="d-flex justify-content-start align-items-start py-3" style={{ borderBottom: '1px solid rgba(0,0,0,0.16)' }}>
                <div className="position-relative">
                    <div
                        className="avatar"
                        style={{
                            width: '130px',
                            height: '130px',
                            borderRadius: '100%',
                            backgroundSize: '100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${api.host}/media/${userData?.avatar})`,
                            boxShadow: '2.0px 2.0px 7px 0px rgba(0,0,0,0.25)'
                        }} />
                    <div>
                        <UserBadge isVerified={userData?.active} isTerminated={!userData?.is_active} isDeleted={userData?.archived} />
                    </div>
                </div>
                <div className="d-flex flex-column mx-2 my-auto">
                    <div className="mx-3 my-0">
                        <small className="m-0 p-0">Username: </small>
                        <small className="m-0 p-0"><b>{userData?.username}</b></small>
                    </div>
                    <div className="mx-3 my-0">
                        <small className="m-0 p-0">Email Address: </small>
                        <small className="m-0 p-0"><b>{userData?.email}</b></small>
                    </div>
                    <div className="mx-3 my-0">
                        <small className="m-0 p-0">Last LogedIn: </small>
                        <small className="m-0 p-0"><b>{api.parseDate(userData?.last_seen)}</b></small>
                    </div>
                </div>
            </div>
            <div className="d-flex py-2">
                <div className="d-flex flex-column mx-2 w-75" style={{ borderRight: '1px solid rgba(0,0,0,0.16)' }}>
                    <div>
                        <small className="m-0 p-0">Full Name: </small>
                        <small className="m-0 p-0"><b>{userData?.first_name || ""} {userData?.last_name || ""}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Username: </small>
                        <small className="m-0 p-0"><b>{userData?.username || ""}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Country: </small>
                        <small className="m-0 p-0"><b>{userData?.country || ""}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Store: </small>
                        <small className="m-0 p-0"><b>{userData?.store_id || ""}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Email Address: </small>
                        <small className="m-0 p-0"><b>{userData?.email || ""}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Registration Date: </small>
                        <small className="m-0 p-0"><b>{api.parseDate(userData?.created_at)}</b></small>
                    </div>
                    <div>
                        <small className="m-0 p-0">Last LogedIn: </small>
                        <small className="m-0 p-0"><b>{api.parseDate(userData?.last_seen)}</b></small>
                    </div>
                </div>
                <div className="w-25 d-flex flex-wrap justify-content-center align-items-start">
                    <LoaderIconButton icon={<MdDelete />} isLoading={false} onClick={(event: any) => { }} toolTip={`Delete ${userData?.first_name} ${userData?.last_name}`} />
                    <LoaderIconButton icon={<RiIndeterminateCircleFill />} isLoading={false} onClick={(event: any) => { }} toolTip={`Terminate ${userData?.first_name} ${userData?.last_name}`} />
                    <LoaderIconButton icon={<MdPassword />} isLoading={false} onClick={(event: any) => { }} toolTip={`Reset ${userData?.first_name} ${userData?.last_name} password`} />
                    <LoaderIconButton icon={<RiLogoutCircleFill />} isLoading={false} onClick={(event: any) => { }} toolTip={`Force ${userData?.first_name} ${userData?.last_name} to logout `} />
                </div>
            </div>
        </div>
    ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <CircularProgress thickness={1.0} size={52} style={{ color: "var(--navActiveTextColor" }} />
            <small className="my-2"><i>Please Wait ....</i></small>
        </div>
    );
}

export default UserDetails;