import Summary from "../components/summary";
import { BiBarChartAlt } from 'react-icons/bi';
import { FaConciergeBell, FaUserFriends } from 'react-icons/fa';
import { openConfirmationModel } from "../components/functions";
import DataTable from "../components/table";
import { useEffect, useState } from "react";
import useApiServices from "../hooks/useAxios";
import { GoogleMap, Marker, useLoadScript  } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import Map from "../specComponents/map";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { AiOutlineShop } from "react-icons/ai";
import { MdLeaderboard } from "react-icons/md";

const columns = [
    { field: 'order', headerName: '#', sortable: true },
    { field: 'date', headerName: 'Complaint Id' },
    { field: 'customer', headerName: 'Complaint Title' },
    { field: 'status', headerName: 'Complaint Comment' },
    { field: 'actions', headerName: '' },
];

const Dashboard = () => {

    const api: any = useApiServices();

    const [revenue, setRevenue]: any = useState(0);
    const [orders, setOrders]: any = useState(0);
    const [patients, setPatients]: any = useState(0);
    const [webSubscribers, setWebSubscribers]: any = useState(0);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "", // process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "AIzaSyBjbCgEjsPopIek1kgyIEzbC0FEFGoGlpA",
        libraries: ["places"]
    })

    const sync = async () => {
    }

    useEffect(() => {
        sync()
        return () => {
            api.abortRequest();
        }
    }, [])

    const test = () => {
        openConfirmationModel({ title: "", message: "", onCancel: async () => { }, onConfirm: async () => { } })
    }

    return (
        <div className="p-2">
            <div className="my-2 d-flex flex-column">
                <div className="summary-section w-100 d-flex flex-wrap">
                    <Summary toolTip="Total Revenue" name="Total Revenue" value={patients} icon={<BiBarChartAlt size={29} />} />
                    <Summary toolTip="Shopes" name="Shopes" value={`${revenue}`} icon={<AiOutlineShop size={29} />} />
                    <Summary toolTip="Suppliers" name="Suppliers" value={orders} icon={<FaUserFriends size={29} />} />
                    <Summary toolTip="Complete Orders Today" name="Orders Today" value={orders} icon={<FaConciergeBell size={29} />} />
                    <Summary toolTip="Top Selling Product" name="Top Selling Product" value={"......."} icon={<MdLeaderboard size={29} />} />
                </div>
            </div>
            <div className="d-flex">
                <div className="custom-card m-2 d-flex flex-column w-100 p-0" style={{overflow: 'hidden'}}>
                    <div style={{height: '430px'}} className="w-100 d-flex flex-column justify-content-start align-items-start">
                        {
                            !isLoaded ? (
                                <div className="m-auto"><CircularProgress/></div>
                            ) : (
                                <Map/>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="custom-card m-2 d-flex flex-column w-100">
                    <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                        <div className="fs-7 my-3 fw-bold">Complaignts</div>
                        <DataTable columns={columns} rows={[]} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;