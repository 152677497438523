import Divider from "@mui/material/Divider";
import { useEffect, useState, useRef} from "react";
import { FaImage } from "react-icons/fa";
import { RiSaveLine } from "react-icons/ri";
import { useRecoilState } from "recoil";
import CardAppBar from "../components/cardAppbar";
import LoaderIconButton from "../components/loaderIconButton";
import { CustomTextField } from "../components/textfield";
import { profile } from "../context/atoms";
import useApiServices from "../hooks/useAxios";
import API from "../services/api.services";

// const api: API = new API()

const Profile = () => {

    const profileImageInputRef: any = useRef(null);
    const [firstName, setFirstName]: any = useState<string>("");
    const [lastName, setLastName]: any = useState<string>("");
    const [phone, setPhone]: any = useState<string>("");
    const [username, setUsername]: any = useState<string>("");
    const [email, setEmail]: any = useState<string>("");
    // ////////////////////////////////////////////////////////////// //
    const [isSaving, setIsSaving]: any = useState<boolean>(false)
    const [profileImage, setProfileImage] = useRecoilState<any>(profile)

    const api: any = useApiServices();

    useEffect(() => {
        setFirstName(api?.firstName ?? "")
        setLastName(api?.lastName ?? "")
        setPhone(api?.phoneNumber ?? "")
        setUsername(api?.username ?? "")
        setEmail(api?.email ?? "")

        return () => {
            setFirstName("");
            setLastName("");
            setPhone("");
            setUsername("");
            setEmail("");
        }
    }, [])

    const saveProfile = async (event: any) => {

    }

    const avatarChangeHandler = async (event: any) => {
        setProfileImage(null)
        
        let file: any = event.target.files[0]
        const formData: FormData = new FormData()

        formData.append("avatar", file)
        let response: any = await api.uploadAvatar(formData).catch((error: any) => { });

        if (response.success) {
            let url: any = `${api?.host}/media/${response['avatar']}`
            sessionStorage.setItem("avatar", url)
            setProfileImage(url)
        } else {
            setProfileImage(api?.avatar);
        }
        event.target.clear()
    }

    return (
        <div className="custom-card m-3 d-flex flex-column">
            <div className="d-flex flex-column w-100 justify-content-between align-items-start">
                <CardAppBar
                    title="My Profile"
                    subTitle="Update your profile"
                    canNavigateBack={false}
                    isProcessing={isSaving}
                >
                    <LoaderIconButton toolTip="Update profile" onClick={saveProfile} icon={<RiSaveLine />} isLoading={isSaving} />
                </CardAppBar>
                <div className="d-flex align-items-center py-2">
                    <div style={{ width: '270px' }}>
                        {
                            profileImage !== null ? (
                                <div className="profile-avatar position-relative bg-light" style={{ height: '250px', width: '250px', backgroundImage: `url(${profileImage})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                                    <div className="hover-card d-flex justify-content-center align-items-center h-100 w-100">
                                        <LoaderIconButton isLoading={profileImage == null} onClick={(e: any) => { profileImageInputRef.current.click() }} toolTip="Upload Avatar">
                                            <FaImage />
                                        </LoaderIconButton>
                                    </div>
                                    <input ref={profileImageInputRef} id="input-avatar" onChange={avatarChangeHandler} type="file" accept="image/png, image/jpg, image/jpeg" className="d-none" />
                                </div>
                            ) : (
                                <div className="profile-avatar position-relative bg-light" style={{ height: '250px', width: '250px', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }} />
                            )
                        }
                    </div>
                    <form onSubmit={saveProfile} className="d-flex flex-column justify-content-start align-items-start my-3 mx-2 w-100" action="">
                        <div className="mx-3">
                            <CustomTextField className="mx-2" disabled={isSaving} onChange={(e: any) => setFirstName(e.target.value)} label={"First Name"} errorField={firstName} />
                            <CustomTextField className="mx-2" disabled={isSaving} onChange={(e: any) => setLastName(e.target.value)} label={"Last Name"} errorField={lastName} />
                        </div>
                        <div className="mx-3">
                            <CustomTextField className="mx-2" disabled={isSaving} onChange={(e: any) => setPhone(e.target.value)} label={"Phone Number"} errorField={phone} />
                            <CustomTextField className="mx-2" disabled={isSaving} label={"Username"} errorField={username} />
                        </div>
                        <div className="mx-3">
                            <CustomTextField className="mx-2" disabled={true} label={"Email Address"} errorField={email} />
                        </div>
                        <Divider orientation="horizontal"/>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Profile;