import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import useApiServices from "../hooks/useAxios";

const Footer = () => {
    const api: any = useApiServices();

    const [networkStatus, setNetweorkStatus] = useState<any>("")

    useEffect(() => {
        // Update network status
        const handleStatusChange = (event:any) => {
            let netStatus: any = navigator?.onLine ? "Online" : "Offline";
            setNetweorkStatus(netStatus);
        };

        console.log(networkStatus)

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [])

    return (
        <footer className="footer d-flex flex-row justify-content-between px-4 py-2">
            <small>Fugas Company LTD<Chip className="mx-2" sx={{ color: 'var(--iconColor)' }} label={api.storeName} size="small" variant="outlined" /></small>
            <small>{
                networkStatus && networkStatus != "" ? (
                    <Chip className="mx-2" sx={{ color: 'var(--iconColor)' }} label={networkStatus} size="small" variant="outlined" />
                ):null
            } All rights reserved ©nexent 2023</small>
            {/* // <small>Crafted with ♥ by nexent team</small> */}
        </footer>
    );
}

export default Footer;