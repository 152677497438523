import { useEffect, useState } from "react";
import DataTable from "../../components/table";
import useApiServices from "../../hooks/useAxios";
import LoaderIconButton from "../../components/loaderIconButton";
import { MdRemoveCircleOutline } from "react-icons/md";
import useModal from "../../hooks/useModalHook";
import { IoIosSend } from "react-icons/io";

const columns = [
    { field: 'no', headerName: '#', sortable: true },
    { field: 'email', headerName: 'Email' },
    { field: 'created_at', headerName: 'Created At', date: true },
    { field: 'updated_at', headerName: 'Updated At', date: true },
    { field: 'actions', headerName: '' },
];

const Invites = () => {

    const api = useApiServices();

    const [tableData, setTableData] = useState<any>([])
    const [emailToBeResent, setEmailToBeResent] = useState<any>(null)
    const confirmationModal = useModal();
    
    // ///////////////////////////////////////////////////////////// //

    const cancelUserInvite: any = async (inviteId: number) => {
        confirmationModal({
            title: "Confirmation",
            message: `This action is ireversible, are you sure you want to cancel the invitation ?`,
            onConfirm: async () => await api.cancelUserInvite(inviteId).catch((error:any)=>{}),
            onCancel: async () => {}
        })
    }
    
    // ///////////////////////////////////////////////////////////// //

    const resendInvite: any = async (user: any) => {
        setEmailToBeResent(user['email'])
        confirmationModal({
            title: "Confirmation",
            message: `Resend invitation to ${user['email']}`,
            onConfirm: async () => await api.resendUserInvite(user['id']).catch((error:any)=>{}),
            onCancel: async () => {}
        })
        setEmailToBeResent(null)
    }

    // ///////////////////////////////////////////////////////////// //

    const sync = async () => {
        let users: any = await api.getUsersInvites();
        let tableData: any = [];
        users = users.data;
        users?.forEach((user: any, index: number) => {
            tableData.push({
                no: index += 1,
                email: user['email'],
                created_at: user['created_at'],
                updated_at: user['updated_at'],
                actions: (
                    <div className="d-flex justify-content-end">
                        <LoaderIconButton isLoading={user['email'] == emailToBeResent} icon={<IoIosSend size={20}/>} onClick={(event:any) => resendInvite(user)} toolTip={"Resend Invitation"} />
                        <LoaderIconButton icon={<MdRemoveCircleOutline size={20} style={{ color: 'var(--textColor)' }} />} onClick={(e:any)=> cancelUserInvite(parseInt(user['id']))} toolTip={"Cancel Invitation"} />
                    </div>
                ),
            })
        })
        setTableData(tableData)
    }

    // ///////////////////////////////////////////////////////////// //
    useEffect(() => {
        sync();
        // ///////////////////////////////////////////////////////////// //
        return () => {
            api.abortRequest();
        }
    }, [])

    return (
        <div className="">
            <DataTable columns={columns} rows={tableData} />
        </div>
    );
}

export default Invites;