import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { confirmationCancel, confirmationConfirm, confirmationMessage, confirmationTitle, openConfirmationMessage } from "../context/atoms";
import { ConfirmationModalParameters } from "../services/interfaces";

const useModal = () => {
    // ///////////////////////////////////////////////////////////// //
    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useRecoilState(openConfirmationMessage);
    const [getConfirmationTitle, setConfirmationTitle] = useRecoilState(confirmationTitle);
    const [getConfirmationMessage, setConfirmationMessage] = useRecoilState(confirmationMessage);
    const [getConfirmationConfirm, setConfirmationConfirm]: any = useRecoilState(confirmationConfirm);

    // ///////////////////////////////////////////////////////////// //
    const modal = async ({ title = "No Title", message = "No Message", onConfirm, onCancel }: ConfirmationModalParameters) => {
        setConfirmationTitle(title);
        setConfirmationMessage(message);
        setOpenConfirmation(true);
        const confirme = async () => await onConfirm()
        const cancel = async () => await onCancel()
        setConfirmationConfirm([confirme, cancel])
    }
    // ///////////////////////////////////////////////////////////// //
    useEffect(() => {
        // setOpenConfirmation(true)
    }, [])

    return modal;
}

export default useModal;